import { useCallback, useEffect } from 'react'
import {
  useSoundActions,
  useGetMasterVolume,
  useGetRaceSoundsVolume,
  useGetCommentatorVolume,
  useGetSoundEffectsVolume,
  useGetBackgroundMusicVolume,
  useGetIsMasterMuted,
  useGetIsBackgroundMusicMuted,
  useGetIsCommentatorMuted,
  useGetIsCommentatorActive
} from 'src/store/sound'
import type { SoundControls, VolumeType, MuteType } from 'src/types/domain/Sounds'
import {
  muteSoundEffects,
  muteBackgroundMusic,
  fadeInBackgroundMusic,
  fadeOutBackgroundMusic,
  setSoundEffectsVolume,
  setBackgroundMusicVolume
} from 'src/utils/soundEffects'
import { SoundSettingsManager } from 'src/utils/soundSettingManager'

export function useSoundControls(): SoundControls {
  const isBrowser = typeof window !== 'undefined'
  const soundSettings = SoundSettingsManager.getInstance()
  const actions = useSoundActions()

  // Initialize sound settings from localStorage on mount
  useEffect(() => {
    if (!isBrowser) return

    const settings = soundSettings.getSettings()

    // First set all volumes
    actions.masterVolumeChanged(settings.volumes.master)
    actions.raceSoundsVolumeChanged(settings.volumes.raceSounds)
    actions.commentatorVolumeChanged(settings.volumes.commentator)
    actions.soundEffectsVolumeChanged(settings.volumes.soundEffects)
    actions.backgroundMusicVolumeChanged(settings.volumes.backgroundMusic)

    // Then apply ALL mute states
    actions.masterMuted(settings.muted.master)
    actions.backgroundMusicMuted(settings.muted.music)
    actions.commentatorMuted(settings.muted.commentator)

    // Handle race sounds muting through volume
    if (settings.muted.raceSounds) {
      actions.raceSoundsVolumeChanged(0)
    }

    if (settings.muted.soundEffects) {
      actions.soundEffectsVolumeChanged(0)
    }

    // Apply to actual audio systems
    setSoundEffectsVolume(settings.volumes.soundEffects)
    setBackgroundMusicVolume(settings.volumes.backgroundMusic)

    // Handle muting for audio systems
    muteSoundEffects(settings.muted.master || settings.muted.soundEffects)
    muteBackgroundMusic(settings.muted.master || settings.muted.music)
  }, [isBrowser, actions])

  const masterVolume = useGetMasterVolume()
  const raceSoundsVolume = useGetRaceSoundsVolume()
  const commentatorVolume = useGetCommentatorVolume()
  const soundEffectsVolume = useGetSoundEffectsVolume()
  const backgroundMusicVolume = useGetBackgroundMusicVolume()
  const isMasterMuted = useGetIsMasterMuted()
  const isMusicMuted = useGetIsBackgroundMusicMuted()
  const isCommentatorMuted = useGetIsCommentatorMuted()
  const isCommentatorActive = useGetIsCommentatorActive()

  // Update sound effects volume when it changes
  useEffect(() => {
    if (!isBrowser) return
    setSoundEffectsVolume(soundEffectsVolume)
    soundSettings.setVolume('soundEffects', soundEffectsVolume)
  }, [soundEffectsVolume, isBrowser])

  // Handle background music volume separately
  useEffect(() => {
    if (!isBrowser) return
    setBackgroundMusicVolume(backgroundMusicVolume)
    soundSettings.setVolume('backgroundMusic', backgroundMusicVolume)
  }, [backgroundMusicVolume, isBrowser])

  // Handle master mute
  useEffect(() => {
    if (!isBrowser) return
    if (isMasterMuted) {
      muteSoundEffects(true)
      muteBackgroundMusic(true)
    } else {
      muteSoundEffects(false)
      muteBackgroundMusic(false)
    }
    soundSettings.setMuted('master', isMasterMuted)
  }, [isMasterMuted, isBrowser])

  const handleMasterVolumeChange = useCallback(
    (newVolume: number) => {
      actions.masterVolumeChanged(newVolume)
      soundSettings.setVolume('master', newVolume)

      if (newVolume === 0) {
        actions.raceSoundsVolumeChanged(0)
        actions.commentatorVolumeChanged(0)
        actions.soundEffectsVolumeChanged(0)
        actions.backgroundMusicVolumeChanged(0)
        return
      }

      const ratio = newVolume / (masterVolume || 1)
      const newRaceSoundsVolume = Math.max(0, Math.round(raceSoundsVolume * ratio))
      const newCommentatorVolume = Math.max(0, Math.round(commentatorVolume * ratio))
      const newSoundEffectsVolume = Math.max(0, Math.round(soundEffectsVolume * ratio))
      const newBackgroundMusicVolume = Math.max(0, Math.round(backgroundMusicVolume * ratio))

      actions.raceSoundsVolumeChanged(newRaceSoundsVolume)
      actions.commentatorVolumeChanged(newCommentatorVolume)
      actions.soundEffectsVolumeChanged(newSoundEffectsVolume)
      actions.backgroundMusicVolumeChanged(newBackgroundMusicVolume)

      soundSettings.setVolume('raceSounds', newRaceSoundsVolume)
      soundSettings.setVolume('commentator', newCommentatorVolume)
      soundSettings.setVolume('soundEffects', newSoundEffectsVolume)
      soundSettings.setVolume('backgroundMusic', newBackgroundMusicVolume)
    },
    [
      actions,
      masterVolume,
      raceSoundsVolume,
      commentatorVolume,
      soundEffectsVolume,
      backgroundMusicVolume
    ]
  )
  type VolumeActionType =
    | 'raceSoundsVolumeChanged'
    | 'commentatorVolumeChanged'
    | 'soundEffectsVolumeChanged'
    | 'backgroundMusicVolumeChanged'

  const volumeChangeActions: Record<Exclude<VolumeType, 'master'>, VolumeActionType> = {
    raceSounds: 'raceSoundsVolumeChanged',
    commentator: 'commentatorVolumeChanged',
    soundEffects: 'soundEffectsVolumeChanged',
    backgroundMusic: 'backgroundMusicVolumeChanged'
  }

  return {
    state: {
      volumes: {
        master: masterVolume,
        raceSounds: raceSoundsVolume,
        commentator: commentatorVolume,
        soundEffects: soundEffectsVolume,
        backgroundMusic: backgroundMusicVolume
      },
      muted: {
        master: isMasterMuted,
        music: isMusicMuted,
        commentator: isCommentatorMuted,
        raceSounds: raceSoundsVolume === 0,
        soundEffects: soundEffectsVolume === 0
      },
      isCommentatorActive
    },
    controls: {
      setVolume: (type: VolumeType, volume: number) => {
        const safeVolume = Math.max(0, Math.min(100, volume))

        if (type === 'master') {
          handleMasterVolumeChange(safeVolume)
          return
        }

        const actionKey = volumeChangeActions[type]
        ;(actions[actionKey] as (volume: number) => void)(safeVolume)
        soundSettings.setVolume(type, safeVolume)
      },
      toggleMute: (type: MuteType) => {
        switch (type) {
          case 'master':
            const newMasterMuted = !isMasterMuted
            actions.masterMuted(newMasterMuted)
            soundSettings.setMuted('master', newMasterMuted)
            muteSoundEffects(newMasterMuted)
            muteBackgroundMusic(newMasterMuted)
            if (newMasterMuted) {
              fadeOutBackgroundMusic(300)
            } else if (!document.hidden) {
              fadeInBackgroundMusic(1000)
            }
            break

          case 'music':
            const newMusicMuted = !isMusicMuted
            actions.backgroundMusicMuted(newMusicMuted)
            soundSettings.setMuted('music', newMusicMuted)
            muteBackgroundMusic(newMusicMuted)
            break

          case 'soundEffects':
            const newEffectsMuted = soundEffectsVolume > 0
            actions.soundEffectsVolumeChanged(newEffectsMuted ? 0 : masterVolume)
            soundSettings.setMuted('soundEffects', newEffectsMuted)
            muteSoundEffects(newEffectsMuted)
            break

          case 'commentator':
            const newCommentatorMuted = !isCommentatorMuted
            actions.commentatorMuted(newCommentatorMuted)
            actions.commentatorVolumeChanged(newCommentatorMuted ? 0 : masterVolume)
            soundSettings.setMuted('commentator', newCommentatorMuted)
            break

          case 'raceSounds':
            const newRaceSoundsMuted = raceSoundsVolume === 0
            actions.raceSoundsVolumeChanged(newRaceSoundsMuted ? masterVolume : 0)
            soundSettings.setMuted('raceSounds', newRaceSoundsMuted)
            break
        }
      }
    }
  }
}
