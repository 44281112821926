import { useState, useEffect } from 'react'

function useDeviceOrientation() {
  const [deviceInfo, setDeviceInfo] = useState({
    isLandscape: false,
    isMobile: false,
    isTouch: false
  })

  useEffect(() => {
    function checkDevice() {
      // Check if device has touch capability
      const hasTouch =
        'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator?.maxTouchPoints > 0

      // Check if it's a mobile device using userAgent
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      const isMobileDevice = mobileRegex.test(navigator.userAgent)

      // Check for tablet specifically
      const isTablet = /iPad|tablet|Nexus(?!.*Mobile)/i.test(navigator.userAgent)

      // Check orientation
      let isLandscape = false

      if (window.screen?.orientation) {
        // Modern API
        isLandscape = window.screen.orientation.type.includes('landscape')
      } else {
        // Fallback
        isLandscape = window.orientation === 90 || window.orientation === -90
      }

      // Check if it's a mobile browser by comparing screen and window dimensions
      const browserScreenRatio =
        Math.min(window.screen.width, window.screen.height) /
        Math.min(window.innerWidth, window.innerHeight)

      // Most mobile browsers have a ratio close to 1, while desktop browsers often don't
      const isMobileBrowser = browserScreenRatio > 0.8 && browserScreenRatio < 1.3

      setDeviceInfo({
        isLandscape,
        isMobile: isMobileDevice && !isTablet,
        isTouch: hasTouch
      })
    }

    // Initial check
    checkDevice()

    // Listen to both orientation and resize events
    window.addEventListener('resize', checkDevice)
    window.addEventListener('orientationchange', checkDevice)

    // Create a media query listener for pointer capability
    const mediaQuery = window.matchMedia('(pointer: coarse)')
    mediaQuery.addListener(checkDevice)

    return () => {
      window.removeEventListener('resize', checkDevice)
      window.removeEventListener('orientationchange', checkDevice)
      mediaQuery.removeListener(checkDevice)
    }
  }, [])

  return deviceInfo
}

export default useDeviceOrientation
