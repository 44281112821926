import { pipe, Array as A } from 'effect';
import { useGetCurrentGame } from 'src/store/game';
import { isMarbleGame } from 'src/types/domain/Game';
import type { Race } from 'src/types/domain/Race';
import { PastRace } from './PastRace';
const isRaceSettled = (race: Race) => pipe(race.raceEvents, A.some(event => event.eventType === 'race_settled'));
export default function PastRacesSidebar() {
  const game = useGetCurrentGame();
  if (!isMarbleGame(game)) {
    return null;
  }
  const races = game.races.filter(isRaceSettled).slice(0, 10);
  return <div className="past-races-sidebar" data-sentry-component="PastRacesSidebar" data-sentry-source-file="PastRacesSidebar.tsx">
      <h3>{game.track.name}</h3>
      <div className="std-table two-rows-multiple-columns">
        <div className="std-table__inner">
          <div className="std-table__inner-body no-max-height">
            {races.length > 0 ? races.map(race => <PastRace game={game} key={race.uuid} race={race} />) : <div className="entry entry--empty">
                <span>No Ended Races</span>
              </div>}
          </div>
        </div>
      </div>
    </div>;
}