import { useState } from 'react';
import { Trans } from 'react-i18next';
import FlagCheckeredIcon from 'src/app/assets/icons/ico-flag-checkered.svg';
import HomeAltIcon from 'src/app/assets/icons/ico-home-alt.svg';
import InfoCircleIcon from 'src/app/assets/icons/ico-info-circle.svg';
import BallPileIcon from 'src/app/assets/icons/ico-ball-pile.svg';
import BullseyeIcon from 'src/app/assets/icons/ico-bullseye.svg';
import RandomIcon from 'src/app/assets/icons/ico-random.svg';
import UserFriendsIcon from 'src/app/assets/icons/ico-user-friends.svg';
import HistoryIcon from 'src/app/assets/icons/ico-history.svg';
import WalletIcon from 'src/app/assets/icons/ico-wallet.svg';
import BarsIcon from 'src/app/assets/icons/ico-bars.svg';
import CogIcon from 'src/app/assets/icons/ico-cog.svg';
import ClipboardListCheckIcon from 'src/app/assets/icons/ico-clipboard-list-check.svg';
import type { MarbleGame } from 'src/types/domain/Game';
import type { Marble } from 'src/types/domain/Marble';
import { useClientSideTranslation } from 'src/services/i18n';
import { useGetCurrentLanguage } from 'src/store/game';
import { MarbleItem } from '../../MarbleItem';
import OddsTable from '../../GameTopToolbar/ToolbarInfo/OddsTable';
import DynamicRTPSection from './DynamicRTPSection';
type Props = Readonly<{
  game: MarbleGame;
}>;
export default function GameRulesSidebar({
  game
}: Props) {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const [activeEntryId, setActiveEntryId] = useState<string | null>(null);
  const handleEntryClick = (entryId: string) => {
    setActiveEntryId(activeEntryId === entryId ? null : entryId);
  };
  interface FAQEntryProps {
    question: string;
    answer: string;
    id: string;
  }
  const FAQEntry: React.FC<FAQEntryProps> = ({
    question,
    answer,
    id
  }) => <div className={`entry ${activeEntryId === id ? 'active' : ''}`} onClick={() => handleEntryClick(id)} data-sentry-component="FAQEntry" data-sentry-source-file="GameRulesSidebar.tsx">
      <h5>{t(question)}</h5>
      <p>{t(answer)}</p>
    </div>;
  return <div className="panel-game-rules" data-sentry-component="GameRulesSidebar" data-sentry-source-file="GameRulesSidebar.tsx">
      <div className="section">
        <h3>{t('Table of contents')}</h3>
        <ul className="table-of-contents">
          <li>
            <a href="#basic-rules">{t('1. Basic Rules')}</a>
          </li>
          <li>
            <a href="#placing-a-bet">{t('2. Placing a Bet')}</a>
          </li>
          <li>
            <a href="#betting-modes">{t('3. Betting Modes')}</a>
          </li>
          <li>
            <a href="#game-flow">{t('4. Game Flow')}</a>
          </li>
          <li>
            <a href="#start-line-shuffling">{t('5. Start Line Shuffling')}</a>
          </li>
          <li>
            <a href="#game-interface">{t('6. Game Interface')}</a>
          </li>
          <li>
            <a href="#error-handling">{t('7. Error Handling')}</a>
          </li>
          <li>
            <a href="#disconnection-policy">{t('8. Disconnection Policy')}</a>
          </li>
          <li>
            <a href="#return-to-player">{t('9. Return to Player (RTP)')}</a>
          </li>
          <li>
            <a href="#important-notes">{t('10. Important Notes')}</a>
          </li>
          <li>
            <a href="#faq">{t('11. Frequently Asked Questions')}</a>
          </li>
        </ul>
      </div>
      <div className="section" id="basic-rules">
        <h3>{t('1. Basic Rules')}</h3>
        <p>
          {' '}
          The objective in <strong>{game.track.name}</strong> is to predict the winning marble - the
          first marble to cross the finish line will be considered the winner. Each race typically
          takes 50-55 seconds on average from betting open to race settled.
        </p>

        <ul>
          <li>
            {t('{{totalMarbles}} unique marbles compete on each race', {
            totalMarbles: game.totalMarbles
          })}
          </li>{' '}
          <li>{t('Every marble has an equal chance of winning')}</li>
          <li>{t('Start positions are randomly shuffled before each race')}</li>
          <li>{t('Players can see the start line before placing bets')}</li>
          <li>
            {t('If 1 or 2 marbles do not survive (DNS) due to getting stuck or leaving the track, they will be ranked in last place(s). If 3 or more marbles DNS, the race will be cancelled and all bets will be refunded.')}
          </li>
        </ul>

        <h4>{t('Marbles assigned to this track')}:</h4>
        <div className="list-marbles">
          {game.marbles.map((marble: Marble) => <div className="intro-marble" key={marble.uuid}>
              <MarbleItem marble={marble} tooltip={marble.color} className={`marble-${marble.color}`} />
            </div>)}
        </div>
      </div>

      <div className="section" id="placing-a-bet">
        <h3>{t('2. Placing a Bet')}</h3>
        <p>
          {t('You can place one bet per race per track and choose between Pick Winner or Pick Order betting modes. Each track has its own fixed minimum and maximum bet amounts, which the player can view either below the race track name or by clicking the track name for more details.')}
        </p>

        <h4>{t('Bet Amount Controls')}</h4>
        <ul>
          <li>
            <Trans i18nKey="Click <1>Min</1> to reset to the lowest allowed amount" data-sentry-element="Trans" data-sentry-source-file="GameRulesSidebar.tsx">
              Click <span className="inline-label">Min</span> to reset to the lowest allowed amount
            </Trans>{' '}
          </li>
          <li>
            <Trans i18nKey="Use <1>1/2</1> to decrease the current amount by half" data-sentry-element="Trans" data-sentry-source-file="GameRulesSidebar.tsx">
              Use <span className="inline-label">1/2</span> to decrease the current amount by half
            </Trans>{' '}
          </li>
          <li>
            <Trans i18nKey="Use <1>x2</1> to double the current amount" data-sentry-element="Trans" data-sentry-source-file="GameRulesSidebar.tsx">
              Use <span className="inline-label">x2</span> to double the current amount
            </Trans>{' '}
          </li>
          <li>{t('Manually input exact amounts by clicking the bet field')}</li>
        </ul>

        <p>
          {t("The game prevents bets exceeding your total balance. If attempted, you'll be notified to adjust your bet amount. The system will automatically adjust oversized bets to the maximum allowed within your current balance.")}
        </p>

        <p>
          {' '}
          <Trans i18nKey="Bets can only be placed during <1>BETTING OPEN</1> and <3>BETTING CLOSING</3> stages" data-sentry-element="Trans" data-sentry-source-file="GameRulesSidebar.tsx">
            Bets can only be placed during <span className="inline-label">BETTING OPEN</span> and{' '}
            <span className="inline-label">BETTING CLOSING</span> stages.
          </Trans>
        </p>

        <h4>{t('Auto Betting')}</h4>
        <p>
          {t('Auto betting is available by clicking on the cog icon next to the place bet button.')}
        </p>
        <ul>
          <li>
            {t('When auto betting is active, the bet button will show a 5 second countdown timer before placing the next bet on a new race.')}
          </li>
          <li>{t('Choose between 5, 10, 20, or unlimited races.')}</li>
          <li>
            {t('You can cancel auto betting at any time by clicking the bet button or by changing your marble selection or bet amount.')}
          </li>
          <li>
            {t('Auto betting will stop if you disconnect, page is refreshed, player changes track, or if the game experiences an error.')}
          </li>
        </ul>
      </div>
      <div className="section" id="betting-modes">
        <h3>{t('3. Betting Modes')}</h3>

        <h4 className="yellow">{t('Pick Winner')}</h4>
        <p>{t('Pick the marble(s) you think will win the race.')}</p>

        <ul>
          <li>
            {t('Select up to {{maxPickableMarbles}} out of {{totalMarbles}} marbles', {
            maxPickableMarbles: game.maxPickableMarbles,
            totalMarbles: game.totalMarbles
          })}
          </li>
          <li>{t('More selections increase winning chances')}</li>
          <li>{t('Payout multiplier decreases with more selections')}</li>
          <li>{t('Win when any of the selected marble finishes first')}</li>
        </ul>

        <h4 className="blue">{t('Pick Order')}</h4>
        <p>{t('Choose between 2 or 3 marbles with two options')}:</p>

        <h5>
          <RandomIcon data-sentry-element="RandomIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
          <span>{t('Any Order:')}</span>
        </h5>
        <ul>
          <li>{t('Selected marbles must finish in top 2 or 3')}</li>
          <li>{t("Order doesn't matter")}</li>
          <li>{t('Lower payout than exact order')}</li>
        </ul>

        <h5>
          <BullseyeIcon data-sentry-element="BullseyeIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
          <span>{t('Exact Order:')}</span>
        </h5>
        <ul>
          <li>{t('Highest payout multiplier')}</li>
          <li>
            {t('Select exact positions')} (<span className="inline-label green">{t('1st')}</span>,{' '}
            <span className="inline-label yellow">{t('2nd')}</span>, or{' '}
            <span className="inline-label blue">{t('3rd')}</span>)
          </li>
          <li>{t('All marbles must finish in their predicted positions')}</li>
        </ul>

        <h4>{t('Odds Table')}</h4>
        <OddsTable data-sentry-element="OddsTable" data-sentry-source-file="GameRulesSidebar.tsx" />
      </div>
      <div className="section" id="game-flow">
        <h3>{t('4. Game Flow')}</h3>
        <p>{t('Each race progresses through 5 stages, lasting 50-55 seconds on average')}:</p>
        <ol>
          <li>
            <strong className="yellow">{t('Betting Open')}</strong>{' '}
            <span className="time-label">{t('10-15 seconds')}</span>
            <ul>
              <li>{t('Place your bets')}</li>
              <li>{t('View marble starting positions')}</li>
            </ul>
          </li>
          <li>
            <strong className="yellow">{t('Betting Closing')}</strong>{' '}
            <span className="time-label">{t('5-10 seconds')}</span>
            <ul>
              <li>{t('Final betting window')}</li>
              <li>{t('Last-second bets may be rejected and refunded')}</li>
            </ul>
          </li>
          <li>
            <strong className="tomato">{t('Race Starting')}</strong>{' '}
            <span className="time-label">{t('30-50 seconds')}</span>
            <ul>
              <li>{t('Marbles line up')}</li>
              <li>{t('Race begins and runs')}</li>
              <li>{t('No more bets accepted')}</li>
            </ul>
          </li>
          <li>
            <strong className="purple">{t('Race Ended')}</strong>{' '}
            <span className="time-label">{t('2-6 seconds')}</span>
            <ul>
              <li>{t('Results recording and confirmation')}</li>
              <li>{t('Winner verification')}</li>
            </ul>
          </li>
          <li>
            <strong className="green">{t('Race Settled')}</strong>{' '}
            <span className="time-label">{t('5-10 seconds')}</span>
            <ul>
              <li>{t('Results displayed')}</li>
              <li>{t('Winnings paid out')}</li>
              <li>{t('Next race preparation begins')}</li>
            </ul>
          </li>
        </ol>
      </div>
      <div className="section" id="start-line-shuffling">
        <h3>{t('5. Start Line Shuffling')}</h3>
        <p>
          {t('Before each race begins, marbles are automatically randomized at the start line. This ensures complete fairness in starting positions. Players can view these positions during the betting phase to help inform their betting decisions.')}
        </p>
      </div>

      <div className="section" id="game-interface">
        <h3>{t('6. Game Interface')}</h3>

        <h4>{t('Main Controls')}</h4>
        <ul className="list-with-icons">
          <li>
            <div className="entry">
              <span className="icon">
                <HomeAltIcon data-sentry-element="HomeAltIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
              </span>
              <span>{t('Return to game lobby/track selection')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <InfoCircleIcon data-sentry-element="InfoCircleIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
              </span>
              <span>{t('View race details and odds table')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <BallPileIcon data-sentry-element="BallPileIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
              </span>
              <span>{t('Select marbles for betting')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <RandomIcon data-sentry-element="RandomIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
              </span>
              <span>{t('Any order (On Pick Order)')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <BullseyeIcon data-sentry-element="BullseyeIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
              </span>
              <span>{t('Fixed order (On Pick Order)')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <span className="icon-text">{t('Min')}</span>
              </span>
              <span>{t('Set minimum bet amount')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <span className="icon-text">{t('1/2')}</span>
              </span>
              <span>{t('Halve current bet')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <span className="icon-text">{t('x2')}</span>
              </span>
              <span>{t('Double current bet')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <UserFriendsIcon data-sentry-element="UserFriendsIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
              </span>
              <span>{t('View current spectator count')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <FlagCheckeredIcon data-sentry-element="FlagCheckeredIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
              </span>
              <span>{t('View past races and results')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <HistoryIcon data-sentry-element="HistoryIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
              </span>
              <span>{t('View your betting history')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <WalletIcon data-sentry-element="WalletIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
              </span>
              <span>{t('Check current balance')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <BarsIcon data-sentry-element="BarsIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
              </span>
              <span>{t('Access settings menu')}</span>
            </div>
          </li>
        </ul>
        <h4>{t('Settings Panel')}</h4>
        <ul className="list-with-icons">
          <li>
            <div className="entry">
              <span className="icon">
                <CogIcon data-sentry-element="CogIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
              </span>
              <span>{t('Player settings, audio and more')}</span>
            </div>
          </li>
          <li>
            <div className="entry">
              <span className="icon">
                <ClipboardListCheckIcon data-sentry-element="ClipboardListCheckIcon" data-sentry-source-file="GameRulesSidebar.tsx" />
              </span>
              <span>{t('Access these rules')}</span>
            </div>
          </li>
        </ul>
      </div>
      <div className="section" id="error-handling">
        <h3>{t('7. Error Handling')}</h3>
        <p>
          {t('If a race encounters an error, the track manager will notify the shift manager and pause the race. Players will be notified immediately. If the issue can be resolved without affecting the race, it will continue normally. Otherwise, the race will be canceled and all bets refunded, typically within a few minutes.')}
        </p>
      </div>
      <div className="section" id="disconnection-policy">
        <h3>{t('8. Disconnection Policy')}</h3>
        <p>
          {t('If you disconnect during a race, any placed bets remain valid and will be settled normally. Upon reconnecting, you can view all bet outcomes in the Bet History panel.')}
        </p>
      </div>
      <div className="section" id="return-to-player">
        <h3>{t('9. Return to Player (RTP)')}</h3>

        <p>
          {t('Each betting mode has its own RTP (Return to Player) percentage and house edge. Below are the theoretical returns for each betting type:')}
        </p>

        <DynamicRTPSection maxPickableMarbles={game.maxPickableMarbles} totalMarbles={game.totalMarbles} data-sentry-element="DynamicRTPSection" data-sentry-source-file="GameRulesSidebar.tsx" />

        <h4>{t('Important Notes:')}</h4>
        <ul>
          <li>
            {t('RTP percentages are theoretical and calculated over a large number of plays and not a guarantee for a single session.')}
          </li>
          <li>{t('All marbles have an equal probability of winning.')}</li>
          <li>
            {t('Higher risk bets (like Pick Order Exact Order) offer the highest potential payouts.')}
          </li>
        </ul>
        <div className="info-box">
          {' '}
          {/* TODO: language string with variables */}
          <strong>💡 Strategy Tip:</strong> While Pick Winner mode offers the highest RTP, pick
          order modes provide opportunities for larger payouts at increased risk. Choose your
          betting strategy based on your risk tolerance and playing style.
        </div>
      </div>
      <div className="section" id="important-notes">
        <h3>{t('10. Important Notes')}</h3>
        <ul>
          <li>{t('Start positions are randomly shuffled before each new race')}</li>
          <li>{t('Players can see the start line before placing bets')}</li>
          {/* <li>{t('AI Commentator is disabled when a live commentator is present')}</li> */}
          <li>
            {t("When first launching a track, you'll see a welcome dialog showing the marbles, betting modes, and a brief introduction")}
          </li>
        </ul>
      </div>
      <div className="section" id="faq">
        <h3>{t('11. Frequently Asked Questions')}</h3>
        <div className="rules-faq">
          <h4>{t('Gameplay')}</h4>
          <FAQEntry answer="Each race typically takes 45-60 seconds to complete, with the entire process (including betting and results) lasting 50-55 seconds on average." id="race-duration" question="How long does a race last?" data-sentry-element="FAQEntry" data-sentry-source-file="GameRulesSidebar.tsx" />

          <FAQEntry answer="If 1 or 2 marbles do not survive (DNS) due to getting stuck or leaving the track, they will be ranked in last place(s). If 3 or more marbles DNS, the race will be cancelled and all bets will be refunded." id="marble-stuck" question="What happens if a marble gets stuck or flies off the track? (DNS)" data-sentry-element="FAQEntry" data-sentry-source-file="GameRulesSidebar.tsx" />

          <FAQEntry answer="In the rare event of a tie, all bets will be refunded and no winners will be declared, ensuring fairness for all players." id="tie-race" question="Can races end in a tie?" data-sentry-element="FAQEntry" data-sentry-source-file="GameRulesSidebar.tsx" />

          <h4>{t('Betting')}</h4>

          <FAQEntry answer="Bets can be placed during the 'Betting Open' phase and 'Betting Closing' phase. A countdown timer will show you exactly how much time remains to place your bet." id="bet-timing" question="When can I place my bets?" data-sentry-element="FAQEntry" data-sentry-source-file="GameRulesSidebar.tsx" />

          <FAQEntry answer="Winnings are paid out automatically and instantly once the race is settled. You'll receive a notification showing your results and winnings." id="winnings-payout" question="How quickly are winnings paid out?" data-sentry-element="FAQEntry" data-sentry-source-file="GameRulesSidebar.tsx" />

          <FAQEntry answer="Don't worry - your bet remains valid and will be settled normally. You can check the outcome in your Bet History when you reconnect." id="disconnect-bet" question="What happens to my bet if I disconnect?" data-sentry-element="FAQEntry" data-sentry-source-file="GameRulesSidebar.tsx" />

          <h4>{t('Technical')}</h4>

          <FAQEntry answer="Yes, all races are broadcast live from our studio. No pre-recorded footage is used, ensuring real-time excitement and fairness." id="live-races" question="Are the races really live?" data-sentry-element="FAQEntry" data-sentry-source-file="GameRulesSidebar.tsx" />

          <FAQEntry answer="Yes, you can watch races without placing bets. However, after a period of inactivity, you may need to either place a bet or refresh your session to continue watching." id="watch-without-betting" question="Can I watch without betting?" data-sentry-element="FAQEntry" data-sentry-source-file="GameRulesSidebar.tsx" />

          <FAQEntry answer="The game supports multiple languages. You can change your preferred language in the settings menu." id="language-support" question="What languages are supported?" data-sentry-element="FAQEntry" data-sentry-source-file="GameRulesSidebar.tsx" />

          {/* <h4>{t('Commentary')}</h4>
           <FAQEntry
            answer="You can choose between AI commentary or live commentators (when available) through the settings panel. AI commentary is off by default and can be enabled at any time."
            id="commentator-system"
            question="How does the commentator system work?"
           /> */}
        </div>
        <p>
          Need more help? Click the <ClipboardListCheckIcon data-sentry-element="ClipboardListCheckIcon" data-sentry-source-file="GameRulesSidebar.tsx" /> in the settings menu to return to
          these rules at any time. {/* TODO: language string with variables */}
        </p>
      </div>
    </div>;
}