import { useEffect, useState } from 'react';
import { useClientSideTranslation } from 'src/services/i18n';
import { useGetCurrentLanguage } from 'src/store/game';
import { shuffleArray } from 'src/utils/shuffleArray';
export function LoadingMessages() {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const [currentMessage, setCurrentMessage] = useState('');
  const messages = [t('Loading Track...'), t('Getting Marbles Ready...'), t('Preparing Live Stream...'), t('Polishing the Track...'), t('Waking Up the Commentators...'), t('Calibrating the Cameras...'), t('Stretching the Marbles...'), t('Adjusting the Gravity...'), t('Feeding the Marbles...'), t('Applying Racing Stripes...'), t('Consulting the Racing Manual...'), t('Performing Pre-Race Rituals...'), t('Gathering Good Luck Charms...'), t('Studying the Marble Horoscopes...')];
  useEffect(() => {
    const shuffledMessages = shuffleArray(messages);
    if (shuffledMessages[0]) {
      setCurrentMessage(shuffledMessages[0]);
    }
    let index = 1;
    const interval = setInterval(() => {
      const message = shuffledMessages[index];
      if (message) {
        setCurrentMessage(message);
      }
      index = (index + 1) % shuffledMessages.length;
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return <span className="loading-message" data-sentry-component="LoadingMessages" data-sentry-source-file="LoadingMessages.tsx">{currentMessage}</span>;
}