import type { ButtonHTMLAttributes } from 'react';
import { playSoundEffect } from 'src/utils/soundEffects';
type Props = Readonly<{
  onClick: () => void;
  children: React.ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  className?: string;
}>;
export function ClearButton({
  onClick,
  children,
  className,
  type = 'button'
}: Props) {
  return <button className={className} onClick={() => {
    playSoundEffect('click');
    onClick();
  }} type={type} data-sentry-component="ClearButton" data-sentry-source-file="index.tsx">
      {children}
    </button>;
}