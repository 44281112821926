'use client';

import { useState, useEffect } from 'react';
export function Logo() {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const scrollContainer = document.querySelector('.marbles-game');
    if (!scrollContainer) {
      return;
    }
    const handleScroll = () => {
      setIsScrolled(scrollContainer.scrollTop > 0);
    };

    // Check initial scroll position
    handleScroll();
    scrollContainer.addEventListener('scroll', handleScroll, {
      passive: true
    });
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return <div className={`marbles-logo ${isScrolled ? 'scrolled' : ''}`} data-scrolled={isScrolled} data-sentry-component="Logo" data-sentry-source-file="Logo.tsx" />;
}
export default Logo;