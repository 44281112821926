import { Brand } from 'effect'
import { z } from 'zod'

export type RaceConfigurationRowDTO = {
  active: boolean
  created_at: string
  id: string
  laps: number
  max_pickable_marbles: number
  name: string
  preview_thumbnail: string | null
  total_marbles: number
  track_id: string
} & Brand.Brand<'RaceConfigurationRowDTO'>

export const RaceConfigurationRowDTORawSchema = z.object({
  active: z.boolean(),
  created_at: z.string(),
  id: z.string(),
  laps: z.number(),
  max_pickable_marbles: z.number(),
  name: z.string(),
  preview_thumbnail: z.string().nullable(),
  total_marbles: z.number(),
  track_id: z.string()
})

export const brandToRaceConfigurationRowDTO = Brand.nominal<RaceConfigurationRowDTO>()

export const RaceConfigurationRowDTOSchema = RaceConfigurationRowDTORawSchema.transform(
  brandToRaceConfigurationRowDTO
)
