import { useClientSideTranslation } from 'src/services/i18n';
import { useGetCurrentLanguage } from 'src/store/game';
import type { Hero } from 'src/types/infra/supabase';
import MarblesSymbolWhite from 'src/app/assets/icons/ico-marbles-symbol.svg';
type Props = Readonly<{
  hero?: Hero;
}>;
export function AnonymousPlayerView({
  hero
}: Props) {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  return <div className="race-loading-indicator-outer" data-sentry-component="AnonymousPlayerView" data-sentry-source-file="AnonymousPlayerView.tsx">
      <div className="race-loading-indicator">
        <div className="race-loading-indicator__inner">
          <MarblesSymbolWhite data-sentry-element="MarblesSymbolWhite" data-sentry-source-file="AnonymousPlayerView.tsx" />
          <div className="text">
            <span className="primary">{t('We were not able to authenticate you.')}</span>
            <span className="secondary">{t('Please try again.')}</span>
          </div>
        </div>
      </div>
      {hero ? <div className="loading-indicator__background">
          {hero.image ? <video autoPlay loop muted playsInline poster={`/tracks-backgrounds/${hero.image}.webp`}>
              <source src={`/tracks-backgrounds/${hero.image}-video.mp4`} type="video/mp4" />
              <source src={`/tracks-backgrounds/${hero.image}-video.webm`} type="video/webm" />
            </video> : null}
        </div> : <div className="loading-indicator__background">
          <video autoPlay loop muted playsInline poster="/marbles-racetrack-placeholder.webp">
            <source src="/marbles-cinematic-trailer.mp4" type="video/mp4" />
            <source src="/marbles-cinematic-trailer.webm" type="video/webm" />
          </video>
        </div>}
    </div>;
}