import { z } from 'zod'

export const SupabaseInsertEventDTOSchema = z.object({
  eventType: z.literal('INSERT'),
  new: z.record(z.string(), z.unknown()),
  old: z.null()
})

export const SupabaseUpdateEventDTOSchema = z.object({
  eventType: z.literal('UPDATE'),
  new: z.record(z.string(), z.unknown()),
  old: z.record(z.string(), z.unknown())
})

export const SupabaseDeleteEventDTOSchema = z.object({
  eventType: z.literal('DELETE'),
  new: z.null(),
  old: z.record(z.string(), z.unknown())
})

export const SupabaseEventDTOSchema = z.union([
  SupabaseInsertEventDTOSchema,
  SupabaseUpdateEventDTOSchema,
  SupabaseDeleteEventDTOSchema
])

export type SupabaseInsertEventDTO = z.infer<typeof SupabaseInsertEventDTOSchema>
export type SupabaseUpdateEventDTO = z.infer<typeof SupabaseUpdateEventDTOSchema>
export type SupabaseDeleteEventDTO = z.infer<typeof SupabaseDeleteEventDTOSchema>

export type SupabaseEventDTO =
  | SupabaseInsertEventDTO
  | SupabaseUpdateEventDTO
  | SupabaseDeleteEventDTO

export const isSupabaseInsertEventDTO = (
  value: SupabaseEventDTO
): value is SupabaseInsertEventDTO => {
  return value.eventType === 'INSERT'
}

export const isSupabaseUpdateEventDTO = (
  value: SupabaseEventDTO
): value is SupabaseUpdateEventDTO => {
  return value.eventType === 'UPDATE'
}

export const isSupabaseDeleteEventDTO = (
  value: SupabaseEventDTO
): value is SupabaseDeleteEventDTO => {
  return value.eventType === 'DELETE'
}
