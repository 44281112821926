import { findConfig } from '@heathmont/money'

export const getCurrencySymbol = (currency: string) => {
  try {
    const config = findConfig(currency, currency)
    return config.symbol
  } catch (error) {
    return '$'
  }
}
