import { Console, Effect, pipe, Array } from 'effect'
import { isNonEmptyArray } from 'effect/Array'
import type { Tables } from 'src/types/infra/supabase/database.types'
import { RaceMap } from 'src/types/mappers/RaceMap'
import type { Race } from 'src/types/domain/Race'
import { isMarbleGame, unknownGame, type Game } from 'src/types/domain/Game'
import { GameMap } from 'src/types/mappers/GameMap'
import { AppError } from 'src/types/domain/AppError'
import { supabase } from './client'

export type RealtimeTrackDTO = Tables<'track'>
export type RealtimeTrackModeDTO = Tables<'track_mode'>

export type GameDTO = Tables<'race_configuration'> & {
  track: TrackDTO
  marble: Tables<'marble'>[]
}

export type TrackModeDTO = Tables<'track_mode'>

const getRaceConfigurationTask = (gameId: string) => {
  return pipe(
    Effect.tryPromise({
      // TODO: validate if data is indeed a GameDTO, no?
      try: () =>
        supabase
          .from('race_configuration')
          .select(
            `
              *,
              marble(*),
              track:track_id(
                *,
                track_mode(*)
              )
            `
          )
          .eq('id', gameId)
          .limit(1)
          .single<GameDTO>()
          .throwOnError(),
      catch: (error) => {
        console.error({ error })
        return AppError({
          message: `Error fetching game with id: ${gameId}`,
          type: 'UnknownGame'
        })
      }
    }),
    Effect.map(({ data }) => data)
  )
}

export const getGameById = ({ gameId }: { gameId: string }): Effect.Effect<Game, AppError> => {
  const program = pipe(
    Effect.all(
      [
        getRaceConfigurationTask(gameId),
        getRacesByGameId({ gameId, limit: 10, orderBy: 'created_at', order: 'desc' })
      ],
      {
        concurrency: 'unbounded'
      }
    ),
    Effect.tapError(Console.error),
    Effect.map(([raceConfigData, raceData]) => {
      if (!raceConfigData || !isNonEmptyArray(raceData)) {
        return unknownGame
      }

      return GameMap.fromGameDTO(raceConfigData, raceData)
    })
  )

  return program
}

export type RaceDTO = Tables<'race'> & {
  race_event: Tables<'race_event'>[]
}

export type TrackDTO = Tables<'track'> & { track_mode: TrackModeDTO[] }

export const getRacesByGameId = ({
  gameId,
  orderBy = 'created_at',
  order = 'desc',
  limit = 10
}: {
  gameId: string
  orderBy?: 'created_at'
  order?: 'asc' | 'desc'
  limit?: number
}): Effect.Effect<Race[], AppError> => {
  const program = pipe(
    Effect.tryPromise({
      try: () =>
        supabase
          .from('race')
          .select(
            `
            *,
            race_event(*)
            `
          )
          .eq('race_configuration_id', gameId)
          .order(orderBy, { ascending: order === 'asc' })
          .limit(limit)
          .returns<RaceDTO[]>()
          .throwOnError(),
      catch: (error) => {
        console.error({ error })
        return AppError({
          message: `Error fetching races by game id: ${gameId}`,
          type: 'GetRacesByGameIdError'
        })
      }
    }),
    Effect.map(({ data }) => data?.map(RaceMap.fromRaceDTO) ?? [])
  )

  return program
}

export const getRacesByIds = ({
  ids,
  orderBy = 'created_at',
  order = 'desc',
  limit = 10
}: {
  ids: string[]
  orderBy?: 'created_at'
  order?: 'asc' | 'desc'
  limit?: number
}): Effect.Effect<Race[], AppError> => {
  const program = pipe(
    Effect.tryPromise({
      try: () =>
        supabase
          .from('race')
          .select(
            `
            *,
            race_event(*)
            `
          )
          .in('id', ids)
          .order(orderBy, { ascending: order === 'asc' })
          .limit(limit)
          .returns<RaceDTO[]>()
          .throwOnError(),
      catch: (error) => {
        console.error({ error })
        return AppError({
          message: `Error fetching races by ids: ${ids.toString()}`,
          type: 'GetRacesByGameIdError'
        })
      }
    }),
    Effect.map(({ data }) => data?.map(RaceMap.fromRaceDTO) ?? [])
  )

  return program
}

export const getLatestRaceByGameId = (gameId: string): Effect.Effect<Race, AppError> => {
  const program = pipe(
    Effect.tryPromise({
      try: () =>
        supabase
          .from('race')
          .select(
            `
              *,
              race_event(*)
            `
          )
          .eq('race_configuration_id', gameId)
          .order('created_at', { ascending: false })
          .limit(1)
          .single<RaceDTO>()
          .throwOnError(),
      catch: (error) => {
        console.error({ error })
        return AppError({
          message: `Error fetching race with gameId: ${gameId}`,
          type: 'UnknownRace'
        })
      }
    }),
    Effect.filterOrFail(
      (result) => result.data !== null,
      () => AppError({ message: `No race found for: ${gameId}`, type: 'RaceNotFound' })
    ),
    Effect.map(({ data }) => RaceMap.fromRaceDTO(data))
  )

  return program
}

export type RaceConfigurationDTO = Tables<'race_configuration'> & {
  marble: Tables<'marble'>[]
  track: TrackDTO
  race: (Tables<'race'> & {
    race_event: Tables<'race_event'>[]
  })[]
}

export const getActiveGames = () => {
  return pipe(
    Effect.tryPromise({
      try: () =>
        supabase
          .from('race_configuration')
          .select(
            `
              *,
              marble(
                id,
                color,
                image,
                race_configuration_id
              ),
              track:track_id(
                *,
                track_mode(*)
              ),
              race(
                id,
                created_at,
                round,
                race_configuration_id,
                race_event(
                  id,
                  race_id,
                  event_type,
                  event_timestamp,
                  race_configuration_id,
                  payload
                )
              )
            `
          )
          .eq('active', true)
          .eq('track.active', true)
          .eq('track.track_mode.active', true)
          .not('track', 'is', null)
          .order('created_at', { ascending: false, referencedTable: 'race' })
          .order('event_timestamp', { ascending: false, referencedTable: 'race.race_event' })
          .limit(5, { foreignTable: 'race' })
          .returns<RaceConfigurationDTO[]>()
          .throwOnError(),
      catch: (error) => {
        console.error({ error })
        return AppError({
          message: `Error fetching active games`,
          type: 'UnknownGame'
        })
      }
    }),
    Effect.map(({ data }) => data ?? []),
    Effect.map(Array.map(GameMap.fromRaceConfigurationDTO)),
    Effect.map(Array.filter(isMarbleGame))
  )
}
