import { useClientSideTranslation } from 'src/services/i18n';
import AngleLeftIcon from 'src/app/assets/icons/ico-angle-left.svg';
import FlagCheckeredIcon from 'src/app/assets/icons/ico-flag-checkered.svg';
import { useGetCurrentLanguage } from 'src/store/game';
import PastRacesSidebar from '../../PastRacesSidebar';
type Props = Readonly<{
  onBackClick: () => void;
}>;
export function PastRacesPanel({
  onBackClick
}: Props) {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  return <div data-sentry-component="PastRacesPanel" data-sentry-source-file="PastRacesPanel.tsx">
      <div className="panel-header">
        <button className="back" onClick={onBackClick} type="button">
          <span className="icon">
            <AngleLeftIcon data-sentry-element="AngleLeftIcon" data-sentry-source-file="PastRacesPanel.tsx" />
          </span>
        </button>
        <FlagCheckeredIcon data-sentry-element="FlagCheckeredIcon" data-sentry-source-file="PastRacesPanel.tsx" />
        <span>{t('Past Races')}</span>
      </div>
      <PastRacesSidebar data-sentry-element="PastRacesSidebar" data-sentry-source-file="PastRacesPanel.tsx" />
    </div>;
}