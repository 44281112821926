import React, { useCallback, useEffect } from 'react';
import { useGetIsMasterMuted, useSoundActions } from 'src/store/sound';
import { SoundSettingsManager } from 'src/utils/soundSettingManager';
import { muteSoundEffects, muteBackgroundMusic } from 'src/utils/soundEffects';
import VolumeMuteIcon from 'src/app/assets/icons/ico-volume-mute.svg';
import VolumeIcon from 'src/app/assets/icons/ico-volume.svg';
import ExpandIcon from 'src/app/assets/icons/ico-expand.svg';
type Props = Readonly<{
  onToggleFullscreen?: () => void;
  onToggleMute?: () => void;
  videoSourceCount?: number;
}>;
export default function BroadcastControls({
  onToggleFullscreen,
  videoSourceCount
}: Props) {
  const {
    masterMuted
  } = useSoundActions();
  const isMasterMuted = useGetIsMasterMuted();
  const soundSettings = SoundSettingsManager.getInstance();

  // Initialize from localStorage on mount
  useEffect(() => {
    const settings = soundSettings.getSettings();
    masterMuted(settings.muted.master);
    muteSoundEffects(settings.muted.master);
    muteBackgroundMusic(settings.muted.master);
  }, [masterMuted, soundSettings]);
  const toggleMute = useCallback(() => {
    const newMutedState = !isMasterMuted;
    masterMuted(newMutedState);
    soundSettings.setMuted('master', newMutedState);

    // Handle all audio systems
    muteSoundEffects(newMutedState);
    muteBackgroundMusic(newMutedState);

    // Handle video elements
    document.querySelectorAll('.marbles-game__show-broadcast video').forEach(video => {
      ;
      (video as HTMLVideoElement).muted = newMutedState;
    });
  }, [masterMuted, isMasterMuted, soundSettings]);
  const handleMuteClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    toggleMute();
  };
  return <div className="broadcast-controls" data-sentry-component="BroadcastControls" data-sentry-source-file="BroadcastControls.tsx">
      {onToggleFullscreen && (videoSourceCount ?? 0) > 0 ? <button className="game-toolbar-btn mobile-hidden" onClick={onToggleFullscreen} type="button">
          <ExpandIcon />
        </button> : null}
      <button className="game-toolbar-btn" onClick={handleMuteClick} type="button">
        {isMasterMuted ? <VolumeMuteIcon /> : <VolumeIcon />}
      </button>
    </div>;
}