'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useClientSideTranslation } from 'src/services/i18n';
import { useGetCurrentLanguage } from 'src/store/game';
const IDLE_TIMEOUT = 5 * 60 * 10000;
type Props = Readonly<{
  onReconnect: () => void;
  onIdleStateChange: (isIdle: boolean) => void;
}>;
function IdleTimerBanner({
  onReconnect,
  onIdleStateChange
}: Props) {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const [isIdle, setIsIdle] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    const resetTimer = () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        setIsIdle(true);
      }, IDLE_TIMEOUT);
    };
    const handleUserActivity = () => {
      if (isIdle) {
        return;
      }
      resetTimer();
    };
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);
    resetTimer();
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isIdle]);
  const handleReconnect = () => {
    setIsIdle(false);
    onReconnect();
  };
  useEffect(() => {
    onIdleStateChange(isIdle);
  }, [isIdle, onIdleStateChange]);
  if (!isIdle) {
    return null;
  }
  return <div className="idle-timer-banner" data-sentry-component="IdleTimerBanner" data-sentry-source-file="IdleTimerBanner.tsx">
      <div className="inner">
        <p>{t('You have been idle for a while. Click reconnect to continue')}</p>
        <button onClick={handleReconnect} type="button">
          {t('Reconnect')}
        </button>
      </div>
    </div>;
}
export default IdleTimerBanner;