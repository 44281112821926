import { Brand } from 'effect'
import type { NonEmptyArray } from 'effect/Array'
import { z } from 'zod'

const UnexpectedErrors = {
  UnknownError: 'UnknownError'
}
const BetErrors = {
  NotEnoughMoneyError: 'NotEnoughMoneyError',
  BetRollbackError: 'BetRollbackError',
  BetAlreadyExists: 'BetAlreadyExists',
  RoundClosed: 'RoundClosed',
  RoundMismatch: 'RoundMismatch',
  GameIdMismatch: 'GameIDMismatch',
  PlayerIdMismatch: 'PlayerIDMismatch'
} as const

export const AppErrorTypes = {
  ...BetErrors,
  ...UnexpectedErrors
}

export type AppErrorTypes = (typeof AppErrorTypes)[keyof typeof AppErrorTypes]

export const errorTypeList = Object.values(AppErrorTypes) as NonEmptyArray<
  (typeof AppErrorTypes)[keyof typeof AppErrorTypes]
>

const schema = z.object({
  type: z.enum(errorTypeList),
  message: z.string()
})

export type AppError = z.infer<typeof schema> & Brand.Brand<'AppError'>

export const AppError = Brand.nominal<AppError>()

export const AppErrorSchema = schema.transform(AppError)

export const isKnownAppError = (e: unknown): e is AppError => {
  return AppErrorSchema.safeParse(e).success
}

export const isAppError = (e: unknown): e is AppError => {
  return z
    .object({
      type: z.string(),
      message: z.string()
    })
    .safeParse(e).success
}
