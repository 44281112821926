import { isAuthenticatedPlayer } from 'src/types/domain/Player';
import { getCurrencySymbol } from 'src/utils/getCurrencySymbol';
import { useGetCurrentPlayer } from 'src/store/player';
import { useGetHasInitialBalanceAnimationOccurred, useUIActions } from 'src/store/ui';
import { Balance } from './Balance';
type Props = Readonly<{
  onToggleSidebar: () => void;
  isSidebarOpen: boolean;
}>;
export function BalanceContainer({
  onToggleSidebar,
  isSidebarOpen
}: Props) {
  const player = useGetCurrentPlayer();
  const hasInitialBalanceAnimationOccurred = useGetHasInitialBalanceAnimationOccurred();
  const uiActions = useUIActions();
  if (!isAuthenticatedPlayer(player)) {
    return null;
  }
  const currentCurrency = getCurrencySymbol(player.balance.currency);
  const currentBalance = player.balance.amount;
  return <Balance afterAnimationCallback={() => {
    uiActions.initialBalanceAnimationOccurred();
  }} avatar={player.avatar} currentBalance={currentBalance} currentCurrency={currentCurrency} initialBalance={hasInitialBalanceAnimationOccurred ? currentBalance : 0} isSidebarOpen={isSidebarOpen} onToggleSidebar={onToggleSidebar} data-sentry-element="Balance" data-sentry-component="BalanceContainer" data-sentry-source-file="BalanceContainer.tsx" />;
}