import { formatTimeDifference } from 'src/utils/formatTimeDifference';
import type { MarbleGame } from 'src/types/domain/Game';
import type { Race } from 'src/types/domain/Race';
import { getSortedRaceResults } from 'src/utils/raceUtils';
import { getRaceRoundId } from 'src/utils/getRaceRoundId';
import FlagCheckeredIcon from 'src/app/assets/icons/ico-flag-checkered.svg';
import { MarbleItem } from './MarbleItem';
type Props = Readonly<{
  race: Race;
  game: MarbleGame;
}>;
export function PastRace({
  race,
  game
}: Props) {
  const raceEndTime = race.raceEvents.find(event => event.eventType === 'race_settled')?.eventTimestamp;
  const marbleResults = getSortedRaceResults(race, game.marbles);
  return <div className="entry" key={race.uuid} data-sentry-component="PastRace" data-sentry-source-file="PastRace.tsx">
      <div className="top-bar">
        <div className="race-id">
          <FlagCheckeredIcon data-sentry-element="FlagCheckeredIcon" data-sentry-source-file="PastRace.tsx" /> {getRaceRoundId(race.round, game.track.shortName)}
        </div>
        <div className="time">{raceEndTime ? formatTimeDifference(raceEndTime) : ''}</div>
      </div>
      <div className="bottom-bar bottom-bar--past-races">
        <div className="marbles">
          {marbleResults?.map(({
          marble,
          position,
          positionSuffix
        }) => <MarbleItem className={`marble-${marble.color}`} key={marble.uuid} marble={marble} tooltip={`${marble.color} -> ${position}${positionSuffix}`} />)}
        </div>
      </div>
    </div>;
}