import { useEffect, useState } from 'react'
import { useIsAuthenticated, usePlayerActions } from 'src/store/player'
import { useGameConfig } from 'src/hooks/useGameConfig'
import * as actions from 'src/actions'
import { stripQueryParams } from 'src/utils/stripQueryParams'
import { usePlayerAPI } from './usePlayerAPI'

export const useOneTimeLogin = (otl?: string) => {
  const { fetchCurrentPlayer } = usePlayerAPI()
  const { fetchGameConfig } = useGameConfig()
  const playerActions = usePlayerActions()
  const isAuthenticated = useIsAuthenticated()
  const [token, setToken] = useState<string>()

  useEffect(() => {
    const fetchToken = async (otlCode: string): Promise<void> => {
      try {
        const tokenResponse = await actions.exchangeToken({ otl: otlCode })

        if (tokenResponse.error) {
          console.error('🚀 ~ fetchToken ~ tokenResponse.error:', tokenResponse.error)
          playerActions.notAuthenticated()
          return
        }

        setToken(tokenResponse.data?.accessToken)

        // Strip the 'otl', 'country', and 'language' query parameters from the URL without causing a redirect
        stripQueryParams(['otl', 'country', 'language'])
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('🚀 ~ fetchToken ~ error:', error.message)
        }

        console.error({ error })
      }
    }
    if (otl) {
      void fetchToken(otl)
    }
  }, [otl, playerActions])

  useEffect(() => {
    // If the player is authenticated, we don't need to fetch the player
    // If one time login code is provided, but the token is not set, we don't need to fetch the player
    if (isAuthenticated || (otl && !token)) {
      return
    }

    void fetchCurrentPlayer({ token })
    void fetchGameConfig({ token })
  }, [token, fetchCurrentPlayer, isAuthenticated, otl, fetchGameConfig])
}
