// Base constants that can be used to ensure consistency
export const VOLUME_TYPES = {
  MASTER: 'master',
  RACE_SOUNDS: 'raceSounds',
  COMMENTATOR: 'commentator',
  SOUND_EFFECTS: 'soundEffects',
  BACKGROUND_MUSIC: 'backgroundMusic'
} as const

export const MUTE_TYPES = {
  MASTER: 'master',
  MUSIC: 'music',
  COMMENTATOR: 'commentator',
  RACE_SOUNDS: 'raceSounds',
  SOUND_EFFECTS: 'soundEffects'
} as const

// Derive types from constants for better maintainability
export type VolumeType = (typeof VOLUME_TYPES)[keyof typeof VOLUME_TYPES]
export type MuteType = (typeof MUTE_TYPES)[keyof typeof MUTE_TYPES]

// Derive action types from volume types for consistency
export type VolumeActionType = `${Exclude<VolumeType, 'master'>}VolumeChanged`

// Sound action interfaces
export interface SoundActions {
  masterVolumeChanged: (volume: number) => void
  raceSoundsVolumeChanged: (volume: number) => void
  commentatorVolumeChanged: (volume: number) => void
  soundEffectsVolumeChanged: (volume: number) => void
  backgroundMusicVolumeChanged: (volume: number) => void
  masterMuted: (muted: boolean) => void
  masterMuteToggled: () => void
  backgroundMusicMuted: (muted: boolean) => void
  commentatorMuted: (muted: boolean) => void
  commentatorActivated: (isActive: boolean) => void
}

// Settings interfaces with stronger typing
export interface SoundVolumes extends Record<VolumeType, number> {}

export interface SoundMuteState extends Record<MuteType, boolean> {}

export interface SoundSettings {
  volumes: SoundVolumes
  muted: SoundMuteState
}

// Hook return type
export interface SoundControls {
  state: {
    volumes: SoundVolumes
    muted: SoundMuteState
    isCommentatorActive: boolean
  }
  controls: {
    setVolume: (type: VolumeType, volume: number) => void
    toggleMute: (type: MuteType) => void
  }
}

// Mapping object type with type guard
export const volumeChangeActions: Record<Exclude<VolumeType, 'master'>, VolumeActionType> = {
  [VOLUME_TYPES.RACE_SOUNDS]: 'raceSoundsVolumeChanged',
  [VOLUME_TYPES.COMMENTATOR]: 'commentatorVolumeChanged',
  [VOLUME_TYPES.SOUND_EFFECTS]: 'soundEffectsVolumeChanged',
  [VOLUME_TYPES.BACKGROUND_MUSIC]: 'backgroundMusicVolumeChanged'
} as const

// Type guard functions
export const isVolumeType = (value: string): value is VolumeType => {
  return Object.values(VOLUME_TYPES).includes(value as VolumeType)
}

export const isMuteType = (value: string): value is MuteType => {
  return Object.values(MUTE_TYPES).includes(value as MuteType)
}

// Default values
export const DEFAULT_VOLUMES: SoundVolumes = {
  [VOLUME_TYPES.MASTER]: 100,
  [VOLUME_TYPES.RACE_SOUNDS]: 100,
  [VOLUME_TYPES.COMMENTATOR]: 100,
  [VOLUME_TYPES.SOUND_EFFECTS]: 100,
  [VOLUME_TYPES.BACKGROUND_MUSIC]: 10
}

export const DEFAULT_MUTE_STATE: SoundMuteState = {
  [MUTE_TYPES.MASTER]: false,
  [MUTE_TYPES.MUSIC]: false,
  [MUTE_TYPES.COMMENTATOR]: false,
  [MUTE_TYPES.RACE_SOUNDS]: false,
  [MUTE_TYPES.SOUND_EFFECTS]: false
}
