import HistoryIcon from 'src/app/assets/icons/ico-history.svg';
import { isMarbleGame } from 'src/types/domain/Game';
import { isAuthenticatedPlayer } from 'src/types/domain/Player';
import { useGetCurrentPlayer } from 'src/store/player';
import { useGetCurrentGame, useGetCurrentLanguage } from 'src/store/game';
import { useGetBetsByGameAndPlayer } from 'src/store/bet';
import AngleLeftIcon from 'src/app/assets/icons/ico-angle-left.svg';
import { useClientSideTranslation } from 'src/services/i18n';
import BetHistoryPane from './components/BetHistoryPane';
type Props = Readonly<{
  onBackClick: () => void;
}>;
export function BetHistoryPanelContainer({
  onBackClick
}: Props) {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const currentPlayer = useGetCurrentPlayer();
  const currentGame = useGetCurrentGame();
  const bets = useGetBetsByGameAndPlayer({
    gameId: isMarbleGame(currentGame) ? currentGame.id : '',
    playerId: isAuthenticatedPlayer(currentPlayer) ? currentPlayer.id : ''
  });
  if (!isMarbleGame(currentGame) || !isAuthenticatedPlayer(currentPlayer)) {
    return null;
  }
  return <div data-sentry-component="BetHistoryPanelContainer" data-sentry-source-file="BetHistoryPanelContainer.tsx">
      <div className="panel-header">
        <button className="back" onClick={onBackClick} type="button">
          <span className="icon">
            <AngleLeftIcon data-sentry-element="AngleLeftIcon" data-sentry-source-file="BetHistoryPanelContainer.tsx" />
          </span>
        </button>
        <HistoryIcon data-sentry-element="HistoryIcon" data-sentry-source-file="BetHistoryPanelContainer.tsx" />
        <span>{t('Bet History')}</span>
      </div>
      <BetHistoryPane bets={bets} game={currentGame} player={currentPlayer} raceTrackName={currentGame.track.name} data-sentry-element="BetHistoryPane" data-sentry-source-file="BetHistoryPanelContainer.tsx" />
    </div>;
}