import type { Channel } from 'phoenix'
import { Socket } from 'phoenix'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { isDevtoolsEnabled } from './utils/isDevtoolsEnabled'

interface SocketState {
  socket?: Socket
  playerChannel?: Channel
  gameChannel?: Channel
  raceChannel?: Channel
}

export interface SocketActions {
  createSocket: (scheme: string, url: string, token: string) => void
  joinPlayerChannel: (playerId: string) => Promise<void>
  joinGameChannel: (gameId: string) => Promise<void>
  joinRacesChannel: () => Promise<void>
}

type SocketStore = SocketState & {
  actions: SocketActions
}

export const useSocketStore = create<SocketStore>()(
  devtools(
    immer((set) => ({
      socket: undefined,
      playerChannel: undefined,
      gameChannel: undefined,
      raceChannel: undefined,
      actions: {
        createSocket: (scheme: string, url: string, token: string) => {
          set((state) => {
            state.socket = new Socket(`${scheme}://${url}/socket`, {
              params: { token },
              timeout: 1_000,
              heartbeatIntervalMs: 20_000,
              debug: false,
              reconnectAfterMs: (tries) => {
                // It starts with a base of 500ms and increases by 1000ms for each attempt,
                // capping at a maximum of 10000ms (10 seconds).
                return Math.min(500 + tries * 1000, 10000)
              }
            })

            state.socket.connect()
          })
        },
        joinPlayerChannel: async (playerId: string) => {
          const state = useSocketStore.getState()

          if (!state.socket) {
            console.error('SOCKET NOT CONNECTED')
            return
          }

          const channel = state.socket.channel(`realtime:private:player-${playerId}`)

          try {
            await new Promise((resolve, reject) => {
              channel.join().receive('ok', resolve).receive('error', reject)
            })

            // console.info('PLAYER CHANNEL JOINED', channel.topic)

            set(
              (state) => {
                state.playerChannel = channel
              },
              false,
              'joinPlayerChannel'
            )
          } catch (error) {
            console.error('ERROR JOINING PLAYER CHANNEL', error)

            set(
              (state) => {
                state.playerChannel = undefined
              },
              false,
              'joinPlayerChannel'
            )
          }
        },
        joinGameChannel: async (gameId: string) => {
          const state = useSocketStore.getState()

          if (!state.socket) {
            console.error('SOCKET NOT CONNECTED')
            return
          }

          const channel = state.socket.channel(`realtime:public:game-${gameId}`)

          try {
            await new Promise((resolve, reject) => {
              channel.join().receive('ok', resolve).receive('error', reject)
            })

            // console.info('GAME CHANNEL JOINED', channel.topic)

            set(
              (state) => {
                state.gameChannel = channel
              },
              false,
              'joinGameChannel'
            )
          } catch (error) {
            console.error('ERROR JOINING GAME CHANNEL', error)

            set(
              (state) => {
                state.gameChannel = undefined
              },
              false,
              'joinGameChannel'
            )
          }
        },
        joinRacesChannel: async () => {
          const state = useSocketStore.getState()

          if (!state.socket) {
            console.error('SOCKET NOT CONNECTED')
            return
          }

          const channel = state.socket.channel(`realtime:public:race-api-events`)

          try {
            await new Promise((resolve, reject) => {
              channel.join().receive('ok', resolve).receive('error', reject)
            })

            // console.info('RACE CHANNEL JOINED', channel.topic)

            set(
              (state) => {
                state.raceChannel = channel
              },
              false,
              'joinRaceChannel'
            )
          } catch (error) {
            console.error('ERROR JOINING RACE CHANNEL', error)

            set(
              (state) => {
                state.raceChannel = undefined
              },
              false,
              'joinRaceChannel'
            )
          }
        }
      }
    })),
    { name: 'SocketStore', enabled: isDevtoolsEnabled() }
  )
)

export const useSocketActions = () => useSocketStore((state) => state.actions)

export const useGetSocket = () => useSocketStore((state) => state.socket)

export const useGetPlayerChannel = () => useSocketStore((state) => state.playerChannel)

export const useGetGameChannel = () => useSocketStore((state) => state.gameChannel)

export const useGetRaceChannel = () => useSocketStore((state) => state.raceChannel)
