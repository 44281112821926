'use client';

import { useClientSideTranslation } from 'src/services/i18n';
import { useGetCurrentLanguage } from 'src/store/game';
import MobileIcon from 'src/app/assets/icons/ico-mobile.svg';
export function OrientationMessage() {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  return <div className="race-loading-indicator-outer" data-sentry-component="OrientationMessage" data-sentry-source-file="OrientationMessage.tsx">
      <div className="race-loading-indicator orientation-message">
        <div className="race-loading-indicator__inner">
          {/* <MarblesSymbolWhite /> */}
          <div className="orientation-icon">
            <MobileIcon data-sentry-element="MobileIcon" data-sentry-source-file="OrientationMessage.tsx" />
          </div>
          <div className="text">
            <span className="primary">
              {t('This game is not optimized for mobile landscape orientation.')}
            </span>
            <span className="secondary">{t('Please rotate your device.')}</span>
          </div>
        </div>
      </div>
      <div className="loading-indicator__background">
        <video autoPlay loop muted playsInline poster="/marbles-racetrack-placeholder.webp">
          <source src="/marbles-cinematic-trailer.mp4" type="video/mp4" />
          <source src="/marbles-cinematic-trailer.webm" type="video/webm" />
        </video>
      </div>
    </div>;
}