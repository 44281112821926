import { create } from 'zustand'
import { setAutoFreeze } from 'immer'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import type { SoundActions, SoundVolumes, SoundMuteState } from 'src/types/domain/Sounds'
import {
  VOLUME_TYPES,
  MUTE_TYPES,
  DEFAULT_VOLUMES,
  DEFAULT_MUTE_STATE
} from 'src/types/domain/Sounds'
import { isDevtoolsEnabled } from './utils/isDevtoolsEnabled'

setAutoFreeze(false)

interface SoundState {
  volumes: SoundVolumes
  muted: SoundMuteState
  isCommentatorActive: boolean
}

type SoundStore = SoundState & {
  actions: SoundActions
}

const initialState: SoundState = {
  volumes: DEFAULT_VOLUMES,
  muted: DEFAULT_MUTE_STATE,
  isCommentatorActive: false
}

const useSoundStore = create<SoundStore>()(
  devtools(
    immer((set, _) => ({
      ...initialState,
      actions: {
        masterVolumeChanged: (volume: number) => {
          set(
            (state) => {
              state.volumes.master = volume
            },
            undefined,
            'masterVolumeChanged'
          )
        },
        raceSoundsVolumeChanged: (volume: number) => {
          set(
            (state) => {
              state.volumes.raceSounds = volume
            },
            undefined,
            'raceSoundsVolumeChanged'
          )
        },
        commentatorVolumeChanged: (volume: number) => {
          set(
            (state) => {
              state.volumes.commentator = volume
            },
            undefined,
            'commentatorVolumeChanged'
          )
        },
        soundEffectsVolumeChanged: (volume: number) => {
          set(
            (state) => {
              state.volumes.soundEffects = volume
            },
            undefined,
            'soundEffectsVolumeChanged'
          )
        },
        backgroundMusicVolumeChanged: (volume: number) => {
          set(
            (state) => {
              state.volumes.backgroundMusic = volume
            },
            undefined,
            'backgroundMusicVolumeChanged'
          )
        },
        masterMuted: (isMuted: boolean) => {
          set(
            (state) => {
              state.muted.master = isMuted
            },
            undefined,
            'masterMuted'
          )
        },
        backgroundMusicMuted: (isMuted: boolean) => {
          set(
            (state) => {
              state.muted.music = isMuted
            },
            undefined,
            'backgroundMusicMuted'
          )
        },
        commentatorMuted: (isMuted: boolean) => {
          set(
            (state) => {
              state.muted.commentator = isMuted
            },
            undefined,
            'commentatorMuted'
          )
        },
        masterMuteToggled: () => {
          set(
            (state) => {
              state.muted.master = !state.muted.master
            },
            undefined,
            'masterMuteToggled'
          )
        },
        commentatorActivated: (isActive: boolean) => {
          set(
            (state) => {
              state.isCommentatorActive = isActive
            },
            undefined,
            'commentatorActivated'
          )
        }
      }
    })),
    { name: 'SoundStore', enabled: isDevtoolsEnabled() }
  )
)

// Typed selectors
export const useSoundActions = () => useSoundStore((state) => state.actions)

// Volume selectors
export const useGetMasterVolume = () => useSoundStore((state) => state.volumes[VOLUME_TYPES.MASTER])
export const useGetRaceSoundsVolume = () =>
  useSoundStore((state) => state.volumes[VOLUME_TYPES.RACE_SOUNDS])
export const useGetCommentatorVolume = () =>
  useSoundStore((state) => state.volumes[VOLUME_TYPES.COMMENTATOR])
export const useGetSoundEffectsVolume = () =>
  useSoundStore((state) => state.volumes[VOLUME_TYPES.SOUND_EFFECTS])
export const useGetBackgroundMusicVolume = () =>
  useSoundStore((state) => state.volumes[VOLUME_TYPES.BACKGROUND_MUSIC])

// Mute state selectors
export const useGetIsMasterMuted = () => useSoundStore((state) => state.muted[MUTE_TYPES.MASTER])
export const useGetIsBackgroundMusicMuted = () =>
  useSoundStore((state) => state.muted[MUTE_TYPES.MUSIC])
export const useGetIsCommentatorMuted = () =>
  useSoundStore((state) => state.muted[MUTE_TYPES.COMMENTATOR])

// Commentator state selector
export const useGetIsCommentatorActive = () => useSoundStore((state) => state.isCommentatorActive)

// Optional: Add a selector to get all sound settings at once
export const useGetAllSoundSettings = () =>
  useSoundStore((state) => ({
    volumes: state.volumes,
    muted: state.muted,
    isCommentatorActive: state.isCommentatorActive
  }))
