import * as jose from 'jose'

interface JWTPayload {
  exp?: number
  iat?: number
  sub?: string
  iss?: string
  jti?: string
  aud?: string | string[]
  nbf?: number
}

export interface TokenPayload extends JWTPayload {
  externalId: string
  displayName: string
  payload: {
    sessionId: string
    platform: 'MOBILE' | 'DESKTOP'
    externalLobbyUrl?: string
    externalDepositUrl?: string
    language?: string
    country?: string
  }
}

export const getValidDecodedToken = (token: string): TokenPayload | undefined => {
  try {
    const data: TokenPayload = jose.decodeJwt(token)

    if (data.exp && data.exp < Date.now() / 1000) {
      return undefined
    }

    return data
  } catch (e) {
    console.error({ error: e })
    return undefined
  }
}
