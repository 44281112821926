import { z } from 'zod'
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'
import type { BalanceDTO, BetDTO } from 'src/actions/types'
import type { BetActions } from 'src/store/bet'
import type { GameActions } from 'src/store/game'
import type { PlayerActions } from 'src/store/player'
import type { UIActions } from 'src/store/ui'
import { betStatusMap } from 'src/types/domain/BetStatus'
import { isRaceCancelledEvent, isRaceSettledEvent } from 'src/types/domain/RaceEvent'
import { RaceConfigurationRowDTOSchema } from 'src/types/infra/supabase/RaceConfigurationRowDTO'
import { RaceEventRowDTOSchema } from 'src/types/infra/supabase/RaceEventRowDTO'
import { RaceRowDTOSchema } from 'src/types/infra/supabase/RaceRowDTO'
import {
  SupabaseInsertEventDTOSchema,
  SupabaseUpdateEventDTOSchema
} from 'src/types/infra/supabase/SupabaseEventDTO'
import { TrackModeRowDTOSchema } from 'src/types/infra/supabase/TrackModeRowDTO'
import { TrackRowDTOSchema } from 'src/types/infra/supabase/TrackRowDTO'
import { BetMap } from 'src/types/mappers/BetMap'
import { GameMap } from 'src/types/mappers/GameMap'
import { RaceMap } from 'src/types/mappers/NormalizedRaceMap'
import { RaceEventMap } from 'src/types/mappers/RaceEventMap'
import type { SocketActions } from 'src/store/socket'
import { getBetStatusByRaceSettled } from './betUtils'

export interface Actions {
  bet: BetActions
  player: PlayerActions
  ui: UIActions
  game: GameActions
  socket: SocketActions
}

// BETS

export const handleBetAccepted =
  (actions: Actions, updateBalance: () => Promise<void>) => (data: unknown) => {
    // console.info('[useWebSockets] bet-accepted', data)

    // ! TODO: use zod to validate data

    const payload = (data as { data: { bet: BetDTO; balance: BalanceDTO } }).data
    const bet = BetMap.fromBetDTO(payload.bet)

    // update state
    actions.bet.betAccepted(bet)

    // fetch new balance
    void updateBalance()
  }

export const handleBetRejected =
  (actions: Actions, updateBalance: () => Promise<void>) => (data: unknown) => {
    // console.info('[useWebSockets] bet-rejected', data)

    // ! TODO: use zod to validate data
    const payload = (data as { data: { bet: BetDTO; error_type: string } }).data
    const errorType = payload.error_type
    const betDTO = payload.bet

    // update state
    actions.bet.betRejected(betDTO.id)
    actions.player.betRejected(betDTO.amount)
    actions.ui.betRejected(errorType)

    // fetch new balance
    void updateBalance()
  }

export const handleBetWon =
  (actions: Actions, updateBalance: () => Promise<void>) => (data: unknown) => {
    // console.info('[useWebSockets] betWon', data)

    const betWonDTO = (data as { data: { bet: BetDTO; balance: BalanceDTO } }).data
    const bet = BetMap.fromBetDTO(betWonDTO.bet)

    // update state
    actions.bet.betWon(bet)

    // fetch new balance
    void updateBalance()
  }

export const handleBetVoided =
  (actions: Actions, updateBalance: () => Promise<void>) => (data: unknown) => {
    // console.info('[useWebSockets] betVoided', data)

    const betVoidedDTO = (data as { data: { bet: BetDTO; balance: BalanceDTO } }).data
    const bet = BetMap.fromBetDTO(betVoidedDTO.bet)

    // update state
    actions.bet.betVoided(bet)
    // actions.player.balanceChanged(betVoidedDTO.balance)

    // fetch new balance
    void updateBalance()
  }

// RACE CONFIGURATION

const CustomEventDTOSchema = z.object({
  action: z.string(),
  payload: z.record(z.string(), z.unknown())
})

export const handleRaceConfigurationCreated =
  (actions: Actions, gameId?: string) => (data: unknown) => {
    // validate data
    const event = CustomEventDTOSchema.extend({
      action: z.literal('race-configuration-created'),
      payload: SupabaseInsertEventDTOSchema.extend({
        new: RaceConfigurationRowDTOSchema
      })
    }).parse(data)

    // Ignore race configuration not for this game
    if (gameId && event.payload.new.id !== gameId) {
      return
    }

    // console.info('[useWebSockets] race-configuration-created', data)

    // Update game
    const partialGame = GameMap.fromGameRowDTO(event.payload.new)
    actions.game.updateGameById(partialGame)
  }

export const handleRaceConfigurationUpdated =
  (actions: Actions, gameId?: string) => (data: unknown) => {
    // validate data
    const event = CustomEventDTOSchema.extend({
      action: z.literal('race-configuration-updated'),
      payload: SupabaseUpdateEventDTOSchema.extend({
        new: RaceConfigurationRowDTOSchema
      })
    }).parse(data)

    // Ignore race configuration not for this game
    if (gameId && event.payload.new.id !== gameId) {
      return
    }

    // console.info('[useWebSockets] race-configuration-updated', data)

    // Update game
    const partialGame = GameMap.fromGameRowDTO(event.payload.new)
    actions.game.updateGameById(partialGame)
  }

// RACE

export const handleRaceCreated = (actions: Actions, gameId?: string) => (data: unknown) => {
  // validate data
  const event = CustomEventDTOSchema.extend({
    action: z.literal('race-created'),
    payload: SupabaseInsertEventDTOSchema.extend({
      new: RaceRowDTOSchema
    })
  }).parse(data)

  // Ignore race not for this game
  if (gameId && event.payload.new.race_configuration_id !== gameId) {
    // console.info('[useWebSockets] NO MATCHING GAME ID - race-created', data)
    return
  }

  // console.info('[useWebSockets] race-created', data)

  // Create new race
  const newRace = RaceMap.fromRaceRowDTO(event.payload.new)
  actions.game.raceAdded(newRace)
}

export const handleRaceUpdated = (actions: Actions, gameId?: string) => (data: unknown) => {
  // validate data
  const event = CustomEventDTOSchema.extend({
    action: z.literal('race-updated'),
    payload: SupabaseUpdateEventDTOSchema.extend({
      new: RaceRowDTOSchema
    })
  }).parse(data)

  // Ignore race not for this game
  if (gameId && event.payload.new.race_configuration_id !== gameId) {
    // console.info('[useWebSockets] NO MATCHING GAME ID - race-updated', data)
    return
  }

  // console.info('[useWebSockets] race-updated', data)

  // Update race
  actions.game.raceUpdated(RaceMap.fromRaceRowDTO(event.payload.new))
}

// RACE EVENT

export const handleRaceEventCreated = (actions: Actions, gameId?: string) => (data: unknown) => {
  // validate data
  const event = CustomEventDTOSchema.extend({
    action: z.literal('race-event-created'),
    payload: SupabaseInsertEventDTOSchema.extend({
      new: RaceEventRowDTOSchema
    })
  }).parse(data)

  // Ignore race event not for this game
  if (gameId && event.payload.new.race_configuration_id !== gameId) {
    // console.info('[useWebSockets] NO MATHCING GAME ID - race-event-created', data)
    return
  }

  // console.info('[useWebSockets] race-event-created', data)

  const newRaceEvent = RaceEventMap.fromRaceEventRowDTO(event.payload.new)

  actions.game.raceEventAdded(newRaceEvent)

  if (isRaceSettledEvent(newRaceEvent)) {
    const bet = actions.bet.getBetByRaceId(newRaceEvent.raceId)

    // Optimistic update
    if (bet && getBetStatusByRaceSettled(bet, newRaceEvent) === betStatusMap.WON) {
      actions.bet.betWon(bet)
      actions.player.betWon(bet)
    } else if (bet && getBetStatusByRaceSettled(bet, newRaceEvent) === betStatusMap.LOST) {
      actions.bet.betLost(bet)
    }
  } else if (isRaceCancelledEvent(newRaceEvent)) {
    const bet = actions.bet.getBetByRaceId(newRaceEvent.raceId)

    // Optimistic update
    if (bet) {
      actions.bet.betCancelled(bet)
      actions.player.betCancelled(bet)
    }
  }
}

export const handleRaceEventUpdated = (actions: Actions, gameId?: string) => (data: unknown) => {
  // validate data
  const event = CustomEventDTOSchema.extend({
    action: z.literal('race-event-updated'),
    payload: SupabaseUpdateEventDTOSchema.extend({
      new: RaceEventRowDTOSchema
    })
  }).parse(data)

  // Ignore race event not for this game
  if (gameId && event.payload.new.race_configuration_id !== gameId) {
    return
  }

  // console.info('[useWebSockets] race-event-updated', data)

  const raceEvent = RaceEventMap.fromRaceEventRowDTO(event.payload.new)
  actions.game.raceEventUpdated(raceEvent)
}

// TRACK

export const handleTrackCreated = (_actions: Actions) => (data: unknown) => {
  // console.info('[useWebSockets] track-created', data)
}

export const handleTrackUpdated = (actions: Actions) => (data: unknown) => {
  // console.info('[useWebSockets] track-updated', data)

  // validate data
  const event = CustomEventDTOSchema.extend({
    action: z.literal('track-updated'),
    payload: SupabaseUpdateEventDTOSchema.extend({
      old: TrackRowDTOSchema,
      new: TrackRowDTOSchema
    })
  }).parse(data)

  // Update track
  actions.game.updateTrack(event.payload.new)
}

// TRACK MODE

export const handleTrackModeCreated = (_actions: Actions) => (data: unknown) => {
  // console.info('[useWebSockets] track-mode-created', data)
}

export const handleTrackModeUpdated = (actions: Actions) => (data: unknown) => {
  // console.info('[useWebSockets] track-mode-updated', data)

  const event = CustomEventDTOSchema.extend({
    action: z.literal('track-mode-updated'),
    payload: SupabaseUpdateEventDTOSchema.extend({
      old: TrackModeRowDTOSchema,
      new: TrackModeRowDTOSchema
    })
  }).parse(data)

  // Update track mode
  actions.game.updateTrackMode(event.payload.new)
}

// MARBLE

export const handleMarbleCreated = (_actions: Actions, _gameId?: string) => (data: unknown) => {
  // console.info('[useWebSockets] marble-created', data)
}

export const handleMarbleUpdated = (_actions: Actions, _gameId?: string) => (data: unknown) => {
  // console.info('[useWebSockets] marble-updated', data)
}

export const handleMarbleDeleted = (_actions: Actions, _gameId?: string) => (data: unknown) => {
  // console.info('[useWebSockets] marble-deleted', data)
}

// GAME CONFIG
const BetLimits = z.object({
  min: z.number(),
  max: z.number()
})

const GameConfigDTOSchema = z.object({
  bet: z.object({
    currency: z.string(),
    limits: z.object({
      PickWinner: BetLimits,
      ReverseForecast: BetLimits,
      StraightForecast: BetLimits,
      CombinationTricast: BetLimits,
      Tricast: BetLimits
    })
  })
})

const GameConfigErrorDTOSchema = z.object({
  error: z.string()
})

const GameConfigResponseSchema = z.union([GameConfigDTOSchema, GameConfigErrorDTOSchema])

export const handleGameConfig =
  (actions: Actions, router: AppRouterInstance) => (data: unknown) => {
    // console.info('[useWebSockets] game-config', data)

    const validationResult = GameConfigResponseSchema.safeParse(data)

    if (!validationResult.success) {
      console.error('[useWebSockets] game-config validation failed', validationResult.error)
      return
    }

    const validData = validationResult.data
    // console.info({ validData })
    if ('error' in validData) {
      // Report to Sentry or other error reporting service
      // console.info('No game config,redirecting to lobby')
      // Redirect to lobby
      router.replace('/')
    } else {
      const gameConfig = GameConfigDTOSchema.parse(validData)
      actions.game.setGameConfig(gameConfig)
    }
  }
