import dayjs, { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'

extend(relativeTime)
extend(duration)

interface TimeFormatterOptions {
  detailed?: boolean
  threshold?: number
}

export function formatTimeDifference(
  timestamp: string,
  options: TimeFormatterOptions = {}
): string {
  const { detailed = true, threshold = 60 } = options

  const now = dayjs()
  const then = dayjs(timestamp)
  const diffMinutes = now.diff(then, 'minute')

  if (detailed && diffMinutes < threshold) {
    const diff = now.diff(then, 'second')
    const minutes = Math.floor(diff / 60)
    const seconds = diff % 60

    if (minutes === 0) {
      return `${seconds}sec ago`
    }

    return `${minutes}min ${seconds}sec ago`
  }

  return then.fromNow()
}
