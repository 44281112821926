import Image from 'next/image';
import type { Marble } from 'src/types/domain/Marble';
type Props = Readonly<{
  marble: Pick<Marble, 'number' | 'image'>;
  tooltip?: string;
  className?: string;
}>;
export function MarbleItem({
  marble,
  tooltip,
  className
}: Props) {
  return <div className={`marble ${tooltip ? '' : 'no-tooltip'} marble-xs active ${className}`} data-tooltip={tooltip} data-sentry-component="MarbleItem" data-sentry-source-file="index.tsx">
      {marble.image ? <Image alt="" className="marble-background-image" draggable="false" fill priority sizes="60px" src={marble.image} /> : null}
      {/* <span className="marble-label-number">{marble.number}</span> */}
    </div>;
}