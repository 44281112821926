import { useCallback } from 'react'
import { getValidDecodedToken } from 'src/utils/getValidDecodedToken'
import * as actions from 'src/actions'
import type { TokenGameConfigDTO } from 'src/actions/types'
import { useGameActions } from 'src/store/game'
import { useGetCurrentPlayerToken } from 'src/store/player'

export const useGameConfig = () => {
  const gameActions = useGameActions()
  const tokenFromState = useGetCurrentPlayerToken()

  const fetchGameConfig = useCallback(
    async (args?: { token?: string }) => {
      const { token = tokenFromState } = args ?? {}

      const tokenGameConfigResponse = !token
        ? // session cookie flow
          await actions.getTokenGameConfig()
        : // client side flow
          getGameConfigFromToken(token)

      if (!tokenGameConfigResponse) {
        console.error('Game config not found in token')
        return
      }

      gameActions.tokenGameConfigRetrieved(tokenGameConfigResponse)
    },
    [gameActions, tokenFromState]
  )

  return {
    fetchGameConfig
  }
}

const getGameConfigFromToken = (token: string): TokenGameConfigDTO | undefined => {
  const data = getValidDecodedToken(token)

  if (!data) {
    return undefined
  }

  return {
    externalLobbyUrl: data.payload.externalLobbyUrl,
    externalDepositUrl: data.payload.externalDepositUrl,
    platform: data.payload.platform,
    language: data.payload.language,
    country: data.payload.country
  }
}
