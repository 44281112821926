import { Order } from 'effect'
import type { RaceEvent } from './RaceEvent'

export interface Race {
  uuid: string
  createdAt: string
  round: number
  raceConfigurationId: string
  raceEvents: RaceEvent[]
}

export const orderRacesByCreatedAt = Order.mapInput(
  Order.reverse(Order.string),
  (race: Race) => race.createdAt
)
