import { Brand } from 'effect'
import { z } from 'zod'

export type TrackModeRowDTO = {
  active: boolean
  created_at: string
  id: string
  name: string
  track_id: string | null
  type: string
} & Brand.Brand<'TrackModeRowDTO'>

export const TrackModeRowDTORawSchema = z.object({
  active: z.boolean(),
  created_at: z.string(),
  id: z.string(),
  name: z.string(),
  track_id: z.string().nullable(),
  type: z.string()
})

export const brandToTrackModeRowDTO = Brand.nominal<TrackModeRowDTO>()

export const TrackModeRowDTOSchema = TrackModeRowDTORawSchema.transform(brandToTrackModeRowDTO)
