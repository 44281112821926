import VolumeIcon from 'src/app/assets/icons/ico-volume.svg';
import VolumeMuteIcon from 'src/app/assets/icons/ico-volume-mute.svg';
type Props = Readonly<{
  label: string;
  volume: number;
  setVolume: (volume: number) => void;
  isMuted: boolean;
  toggleMute: () => void;
}>;
export function VolumeControl({
  label,
  volume,
  setVolume,
  isMuted,
  toggleMute
}: Props) {
  return <label data-sentry-component="VolumeControl" data-sentry-source-file="VolumeControl.tsx">
      <span className="label">{label}</span>
      <button className={`inline-btn ${isMuted ? 'muted' : ''}`} onClick={toggleMute} type="button">
        {isMuted ? <VolumeMuteIcon /> : <VolumeIcon />}
      </button>
      <input max="100" min="0" onChange={e => {
      setVolume(Number(e.target.value));
    }} type="range" value={volume} />
      <span className="volume-value">{Math.round(volume)}%</span>
    </label>;
}