import { fromInteger, toInteger } from '@heathmont/money'
import { betStatusDTOMap } from 'src/actions/types'
import type { PlaceBetRequestedDTO, BetDTO, BetStatusDTO } from 'src/actions/types'
import type { Bet } from 'src/types/domain/Bet'
import { betStatusMap, type BetStatus } from 'src/types/domain/BetStatus'

const fromBetStatusDTO = (status: BetStatusDTO): BetStatus => {
  const map = {
    [betStatusDTOMap.PENDING]: betStatusMap.PENDING,
    [betStatusDTOMap.WON]: betStatusMap.WON,
    [betStatusDTOMap.LOST]: betStatusMap.LOST,
    [betStatusDTOMap.VOIDED]: betStatusMap.CANCELLED
  }

  return map[status]
}

export const fromBetDTO = (betDTO: BetDTO): Bet => ({
  id: betDTO.id,
  playerId: betDTO.playerId,
  raceId: betDTO.roundId,
  raceConfigurationId: betDTO.gameId,
  betAmount: fromInteger(betDTO.amount, betDTO.currency),
  currency: betDTO.currency,
  betDetails: betDTO.details,
  status: fromBetStatusDTO(betDTO.status),
  potentialPayout: fromInteger(betDTO.potentialPayout, betDTO.currency),
  createdAt: betDTO.createdAt
})

export const toPlaceBetRequestedDTO = (sessionId: string, bet: Bet): PlaceBetRequestedDTO => ({
  sessionId,
  id: bet.id,
  playerId: bet.playerId,
  roundId: bet.raceId,
  gameId: bet.raceConfigurationId,
  amount: toInteger(bet.betAmount, bet.currency),
  currency: bet.currency,
  details: bet.betDetails
})
