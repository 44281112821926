'use client';

import { useRouter } from 'next/navigation';
import { playSoundEffect } from 'src/utils/soundEffects';
import { GameTopToolbar } from 'src/components/GameTopToolbar/GameTopToolbar';
import { useGetIsSidebarOpen, useUIActions } from 'src/store/ui';
type Props = Readonly<{
  showLogo?: boolean;
  onToggleFullscreen?: () => void;
  videoSourceCount?: number;
}>;
export function GameTopToolbarContainer({
  showLogo = true,
  onToggleFullscreen,
  videoSourceCount
}: Props) {
  // const player = useStore().currentPlayer
  const router = useRouter();
  const {
    sidebarOpenToggled
  } = useUIActions();
  const isSidebarOpen = useGetIsSidebarOpen();
  const handleToggleSidebar = () => {
    sidebarOpenToggled();
    playSoundEffect('click');
  };
  const goToBack = () => {
    router.push('/');
    playSoundEffect('click');
  };
  return <GameTopToolbar goToBack={goToBack} isSidebarOpen={isSidebarOpen} onToggleFullscreen={onToggleFullscreen} onToggleSidebar={handleToggleSidebar} showLogo={showLogo} videoSourceCount={videoSourceCount} data-sentry-element="GameTopToolbar" data-sentry-component="GameTopToolbarContainer" data-sentry-source-file="GameTopToolbarContainer.tsx" />;
}