import { Effect } from 'effect'
import { v5 as uuidv5 } from 'uuid'
import type { NonEmptyArray } from 'effect/Array'
import { toInteger } from '@heathmont/money'
import { betStatusMap } from 'src/types/domain/BetStatus'
import type { BetStatus } from 'src/types/domain/BetStatus'
import { betTypeMap } from 'src/types/domain/BetDetails'
import type { Bet } from 'src/types/domain/Bet'
import type { RaceSettledEvent } from 'src/types/domain/RaceEvent'
import { displayAmount } from './displayAmount'
import { containsSameItems } from './containsSameItems'

export const BetClassNameMap: Record<BetStatus, string> = {
  [betStatusMap.WON]: 'won',
  [betStatusMap.LOST]: 'lost',
  [betStatusMap.CANCELLED]: 'cancelled',
  [betStatusMap.PENDING]: 'pending'
}

export const BetPayoutLabelMap: Record<BetStatus, string> = {
  [betStatusMap.WON]: 'Won',
  [betStatusMap.LOST]: 'Lost',
  [betStatusMap.CANCELLED]: 'Cancelled',
  [betStatusMap.PENDING]: 'Potential Win'
}

export const BetTagMap: Record<BetStatus, string> = {
  [betStatusMap.WON]: 'You won',
  [betStatusMap.LOST]: 'You lost',
  [betStatusMap.CANCELLED]: 'Cancelled',
  [betStatusMap.PENDING]: 'Current bet'
}

export const BetLabelMap: Record<keyof typeof betTypeMap, string> = {
  [betTypeMap.PickWinner]: '1 Winner',
  [betTypeMap.StraightForecast]: '2 Marbles',
  [betTypeMap.ReverseForecast]: '2 Marbles',
  [betTypeMap.CombinationTricast]: '3 Marbles',
  [betTypeMap.Tricast]: '3 Marbles'
}

export const getVisualPotentialPayout = (currentBet: Bet, currentBetStatus: BetStatus) => {
  const BetAmountMap: Record<BetStatus, string> = {
    [betStatusMap.WON]: `${displayAmount(currentBet.potentialPayout.toString(), true)}`,
    [betStatusMap.LOST]: '-',
    [betStatusMap.CANCELLED]: '-',
    [betStatusMap.PENDING]: `${displayAmount(currentBet.potentialPayout.toString(), true)}`
  }

  return BetAmountMap[currentBetStatus]
}

export const namespaceKeys = {
  Win: 'Win',
  Bet: 'Bet',
  Cancel: 'Cancel',
  BetEvent: 'BetEvent'
} as const

export const namespaceKeyList = Object.values(namespaceKeys) as NonEmptyArray<
  (typeof namespaceKeys)[keyof typeof namespaceKeys]
>

// This is almost the same code as in common/src/utils/id.ts

interface AppError {
  type: 'AppError'
  subtype: string
  message: string
  context: Record<string, unknown>
}

const namespaceMap: Record<(typeof namespaceKeys)[keyof typeof namespaceKeys], string> = {
  Win: '6d718b64-d68a-4930-afb2-a3fa3f22bd5b',
  Bet: '9ccc439d-667a-45da-8a00-292995cfb75d',
  Cancel: '79dddad4-659b-4cf4-8648-df3cabf209b9',
  BetEvent: '4a0b99a7-53a6-4fb1-8578-93ddeb502715'
}

export type NamespaceKey = keyof typeof namespaceMap

export const generateIdByData = (
  data: unknown,
  namespaceKey: NamespaceKey
): Effect.Effect<string, AppError> => {
  if (!data) {
    return Effect.fail({
      type: 'AppError',
      subtype: 'GenerateIdByDataError',
      message: 'Failed to generate UUID, data is undefined',
      context: {
        data
      }
    })
  }

  const program = Effect.try({
    try: () => {
      const input = JSON.stringify(data)
      return uuidv5(input, namespaceMap[namespaceKey])
    },
    catch: (error) => {
      console.error(error)
      const appError: AppError = {
        type: 'AppError',
        subtype: 'GenerateIdByDataError',
        message: 'Failed to generate UUID',
        context: {
          data
        }
      }

      return appError
    }
  })

  return program
}

export const generateBetId = (bet: Omit<Bet, 'id'>): Effect.Effect<string, AppError> => {
  return generateIdByData(
    {
      gameId: bet.raceConfigurationId,
      roundId: bet.raceId,
      playerId: bet.playerId,
      amount: toInteger(bet.betAmount, bet.currency),
      currency: bet.currency,
      details: bet.betDetails
    },
    'Bet'
  )
}

export const getBetStatusByRaceSettled = (
  bet: Bet,
  raceSettledEvent: RaceSettledEvent
): BetStatus => {
  const marblesByPosition = new Map(
    raceSettledEvent.payload.results.map((result): [number, string] => [
      Number(result.position),
      result.marbleId
    ])
  )

  return processBetStatus(bet, marblesByPosition)
}

const processBetStatus = (bet: Bet, marblesByPosition: Map<number, string>): BetStatus => {
  const betSelectedItems = bet.betDetails.selectedItems
  const firstPlace = marblesByPosition.get(1)
  const secondPlace = marblesByPosition.get(2)
  const thirdPlace = marblesByPosition.get(3)

  switch (bet.betDetails.type) {
    case 'PickWinner': {
      if (!firstPlace) {
        console.error('Should not happen: no winning marble found')
        return betStatusMap.PENDING
      }

      const pickWinnerMatch = betSelectedItems.includes(firstPlace)

      return pickWinnerMatch ? betStatusMap.WON : betStatusMap.LOST
    }
    case 'StraightForecast': {
      // exact order
      if (!firstPlace || !secondPlace) {
        console.error('Should not happen: no first or second place found')
        return betStatusMap.PENDING
      }

      const straightForecastMatch =
        betSelectedItems[0] === firstPlace && betSelectedItems[1] === secondPlace

      return straightForecastMatch ? betStatusMap.WON : betStatusMap.LOST
    }
    case 'ReverseForecast': {
      // any order
      if (!firstPlace || !secondPlace) {
        console.error('Should not happen: no first or second place found')
        return betStatusMap.PENDING
      }

      const reverseForecastMatch = containsSameItems(betSelectedItems, [firstPlace, secondPlace])

      return reverseForecastMatch ? betStatusMap.WON : betStatusMap.LOST
    }
    case 'Tricast': {
      // exact order
      if (!firstPlace || !secondPlace || !thirdPlace) {
        console.error('Should not happen: no first, second or third place found')
        return betStatusMap.PENDING
      }

      const tricastMatch =
        betSelectedItems[0] === firstPlace &&
        betSelectedItems[1] === secondPlace &&
        betSelectedItems[2] === thirdPlace

      return tricastMatch ? betStatusMap.WON : betStatusMap.LOST
    }
    case 'CombinationTricast': {
      // any order
      if (!firstPlace || !secondPlace || !thirdPlace) {
        console.error('Should not happen: no first, second or third place found')
        return betStatusMap.PENDING
      }

      const combinationTricastMatch = containsSameItems(betSelectedItems, [
        firstPlace,
        secondPlace,
        thirdPlace
      ])

      return combinationTricastMatch ? betStatusMap.WON : betStatusMap.LOST
    }
    default:
      console.error('Should not happen: unknown bet type')
      return betStatusMap.PENDING
  }
}
