'use client';

import { useState, useRef, useEffect, useCallback } from 'react';
import { playSoundEffect } from 'src/utils/soundEffects';
import InfoCircleIcon from 'src/app/assets/icons/ico-info-circle.svg';
import type { MarbleGame } from 'src/types/domain/Game';
import ArrowLeftIcon from 'src/app/assets/icons/ico-arrow-left.svg';
import { useTimer } from 'src/hooks/useTimer';
import { useClientSideTranslation } from 'src/services/i18n';
import { useGetCurrentLanguage } from 'src/store/game';
import MarblesSymbolWhite from 'src/app/assets/icons/ico-marbles-symbol.svg';
import { ClearButton } from '../../ClearButton';
import OddsTable from './OddsTable';
import { getRaceRoundId } from 'src/utils/getRaceRoundId';
type Props = Readonly<{
  game: MarbleGame;
  gameTopToolbarStyle: React.CSSProperties;
  goToBack: () => void;
}>;
export function ToolbarInfo({
  game,
  gameTopToolbarStyle,
  goToBack
}: Props) {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const [isToolbarInfoDropdownActive, setIsToolbarInfoDropdownActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const {
    eventInfo
  } = useTimer({
    race: game.races[0]
  });
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && isToolbarInfoDropdownActive) {
      setIsToolbarInfoDropdownActive(false);
      playSoundEffect('click');
    }
  }, [isToolbarInfoDropdownActive]);
  useEffect(() => {
    if (isToolbarInfoDropdownActive) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isToolbarInfoDropdownActive, handleClickOutside]);
  const toggleToolbarInfoDropdown = () => {
    setIsToolbarInfoDropdownActive(!isToolbarInfoDropdownActive);
    playSoundEffect('click');
  };
  return <div className="game-top-toolbar__left" style={gameTopToolbarStyle} data-sentry-component="ToolbarInfo" data-sentry-source-file="ToolbarInfo.tsx">
      <ClearButton className="game-toolbar-btn back-btn" onClick={goToBack} data-sentry-element="ClearButton" data-sentry-source-file="ToolbarInfo.tsx">
        <ArrowLeftIcon data-sentry-element="ArrowLeftIcon" data-sentry-source-file="ToolbarInfo.tsx" />
        <MarblesSymbolWhite data-sentry-element="MarblesSymbolWhite" data-sentry-source-file="ToolbarInfo.tsx" />
      </ClearButton>
      <div className="toolbar-info" ref={dropdownRef}>
        <ClearButton className={`toolbar-info__button ${isToolbarInfoDropdownActive ? 'active' : ''}`} onClick={toggleToolbarInfoDropdown} data-sentry-element="ClearButton" data-sentry-source-file="ToolbarInfo.tsx">
          <div className="toolbar-info__button__inner">
            <span className="track-name">
              {game.track.name} <InfoCircleIcon data-sentry-element="InfoCircleIcon" data-sentry-source-file="ToolbarInfo.tsx" />
            </span>
            <span className="track-race-round">
              {getRaceRoundId(game.races[0].round, game.track.shortName)}
            </span>
          </div>
        </ClearButton>
        {isToolbarInfoDropdownActive ? <div className="toolbar-info__dropdown">
            <div className="toolbar-info__dropdown__inner">
              <div className="toolbar-info__dropdown__header">
                <span>{t('Track Info')}</span>
              </div>
              <div className="toolbar-info__dropdown__content">
                <div className="entry">
                  <span>{t('Track')}:</span>
                  <span>
                    {game.track.name} &bull; {game.track.mode.name} {game.name}
                  </span>
                </div>

                <div className="entry">
                  <span>{t('Setup')}:</span>
                  <span>
                    {game.totalMarbles} Marbles &bull; {game.laps} Laps
                  </span>
                </div>

                <div className="entry">
                  <span>{t('Race ID')}:</span>
                  <span>#{game.races[0].round}</span>
                </div>
                {eventInfo ? <div className="entry">
                    <span>{eventInfo.label}:</span>
                    <span>{eventInfo.getTime()}</span>
                  </div> : null}
              </div>
              <OddsTable />
            </div>
          </div> : null}
      </div>
    </div>;
}