import {
  SoundSettings,
  VOLUME_TYPES,
  MUTE_TYPES,
  VolumeType,
  MuteType,
  DEFAULT_VOLUMES,
  DEFAULT_MUTE_STATE
} from 'src/types/domain/Sounds'

const STORAGE_KEY = 'soundSettings'

const DEFAULT_SETTINGS: SoundSettings = {
  volumes: {
    master: 100,
    raceSounds: 100,
    commentator: 100,
    soundEffects: 100,
    backgroundMusic: 10
  },
  muted: {
    master: false,
    music: false,
    commentator: false,
    raceSounds: false,
    soundEffects: false
  }
}

export class SoundSettingsManager {
  private static instance: SoundSettingsManager
  private settings: SoundSettings
  private initialSettingsLoaded = false

  private constructor() {
    this.settings = this.loadSettings()
  }

  private loadSettings(): SoundSettings {
    if (typeof window === 'undefined') return DEFAULT_SETTINGS

    try {
      // Only try to load from localStorage if we haven't done it before
      if (!this.initialSettingsLoaded) {
        const stored = localStorage.getItem(STORAGE_KEY)

        if (!stored) {
          // If no stored settings, save defaults immediately
          localStorage.setItem(STORAGE_KEY, JSON.stringify(DEFAULT_SETTINGS))
          this.initialSettingsLoaded = true
          return DEFAULT_SETTINGS
        }

        try {
          const parsed = JSON.parse(stored)

          // Create new state with defaults as base
          const newSettings: SoundSettings = {
            volumes: { ...DEFAULT_VOLUMES },
            muted: { ...DEFAULT_MUTE_STATE }
          }

          // Merge in saved values, ensuring all properties exist
          if (parsed.volumes) {
            Object.keys(DEFAULT_VOLUMES).forEach((key) => {
              const volumeKey = key as VolumeType
              if (typeof parsed.volumes[volumeKey] === 'number') {
                newSettings.volumes[volumeKey] = Math.max(
                  0,
                  Math.min(100, parsed.volumes[volumeKey])
                )
              }
            })
          }

          if (parsed.muted) {
            Object.keys(DEFAULT_MUTE_STATE).forEach((key) => {
              const muteKey = key as MuteType
              if (typeof parsed.muted[muteKey] === 'boolean') {
                newSettings.muted[muteKey] = parsed.muted[muteKey]
              }
            })
          }

          this.initialSettingsLoaded = true
          return newSettings
        } catch (parseError) {
          console.error('Error parsing sound settings:', parseError)
          localStorage.setItem(STORAGE_KEY, JSON.stringify(DEFAULT_SETTINGS))
          return DEFAULT_SETTINGS
        }
      }
    } catch (error) {
      console.error('Error loading sound settings:', error)
      localStorage.setItem(STORAGE_KEY, JSON.stringify(DEFAULT_SETTINGS))
    }

    return DEFAULT_SETTINGS
  }

  public getSettings(): SoundSettings {
    // Always return a fresh copy to prevent mutations
    return {
      volumes: { ...this.settings.volumes },
      muted: { ...this.settings.muted }
    }
  }

  public setVolume(type: VolumeType, volume: number) {
    const safeVolume = Math.max(0, Math.min(100, volume))
    this.settings.volumes[type] = safeVolume
    this.saveSettings()
  }

  public setMuted(type: MuteType, muted: boolean) {
    this.settings.muted[type] = muted
    this.saveSettings()
  }

  private saveSettings() {
    if (typeof window === 'undefined') return
    try {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(this.settings))
    } catch (error) {
      console.error('Error saving sound settings:', error)
    }
  }

  public static getInstance(): SoundSettingsManager {
    if (!SoundSettingsManager.instance) {
      SoundSettingsManager.instance = new SoundSettingsManager()
    }
    return SoundSettingsManager.instance
  }

  public resetToDefaults() {
    this.settings = {
      volumes: { ...DEFAULT_VOLUMES },
      muted: { ...DEFAULT_MUTE_STATE }
    }
    this.saveSettings()
  }
}
