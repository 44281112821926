import { Howl } from 'howler'
import { SoundSettingsManager } from './soundSettingManager'
import { backgroundMusicManager } from './BackgroundMusicManager'

type SoundEffectNames =
  | 'panelReveal'
  | 'panelRevealReverse'
  | 'cancel'
  | 'click'
  | 'chip'
  | 'switch'
  | 'betPlaced'
  | 'marbleSelected'
  | 'marbleSelectedReverse'
  | 'notification'
  | 'notificationRaceScheduled'
  | 'notificationRaceReady'
  | 'notificationRaceStarting'
  | 'notificationRaceStarted'
  | 'notificationRaceStartedAlt'
  | 'notificationRaceEnded'
  | 'notificationRaceSettled'
  | 'notificationRaceSettledWon1'
  | 'notificationRaceSettledLost1'
  | 'notificationRaceSettledLost2'

class SoundEffectsManager {
  protected soundEffects!: Record<SoundEffectNames, Howl>
  protected volume = 1
  protected isMuted = false
  private isTabFocused = true

  // Define relative volumes in a static configuration
  private static readonly VOLUME_MODIFIERS: Partial<Record<SoundEffectNames, number>> = {
    switch: 0.2,
    marbleSelected: 0.2,
    marbleSelectedReverse: 0.2,
    notification: 0.2,
    notificationRaceScheduled: 0.1,
    notificationRaceReady: 0.1,
    notificationRaceStarting: 0.1,
    notificationRaceStarted: 0.1,
    notificationRaceStartedAlt: 0.1,
    notificationRaceEnded: 0.1,
    notificationRaceSettled: 0.1,
    notificationRaceSettledWon1: 0.1,
    notificationRaceSettledLost1: 0.1,
    notificationRaceSettledLost2: 0.1
  }

  constructor() {
    if (typeof window === 'undefined') return

    const soundSettings = SoundSettingsManager.getInstance()
    const settings = soundSettings.getSettings()
    this.volume = settings.volumes.soundEffects / 100
    this.isMuted = settings.muted.master || settings.muted.soundEffects

    this.soundEffects = {
      panelReveal: new Howl({
        src: ['/sounds/panel-reveal.aac', '/sounds/panel-reveal.mp3'],
        volume: this.volume
      }),
      panelRevealReverse: new Howl({
        src: ['/sounds/panel-reveal.aac', '/sounds/panel-reveal.mp3'],
        rate: 0.8,
        volume: this.volume
      }),
      cancel: new Howl({
        src: ['/sounds/cancel.aac', '/sounds/cancel.mp3'],
        volume: this.volume
      }),
      click: new Howl({
        src: ['/sounds/click.aac', '/sounds/click.mp3'],
        volume: this.volume
      }),
      chip: new Howl({
        src: ['/sounds/chip.aac', '/sounds/chip.mp3'],
        volume: this.volume
      }),
      switch: new Howl({
        src: ['/sounds/switch.aac', '/sounds/switch.mp3'],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.switch!,
        rate: 1.1
      }),
      betPlaced: new Howl({
        src: ['/sounds/success.aac', '/sounds/success.mp3'],
        volume: this.volume
      }),
      marbleSelected: new Howl({
        src: ['/sounds/marble-select.aac', '/sounds/marble-select.mp3'],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.marbleSelected!,
        rate: 1.1
      }),
      marbleSelectedReverse: new Howl({
        src: ['/sounds/marble-select.aac', '/sounds/marble-select.mp3'],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.marbleSelectedReverse!,
        rate: 0.9
      }),
      notification: new Howl({
        src: ['/sounds/notifications/notification.aac', '/sounds/notifications/notification.mp3'],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.notification!
      }),
      notificationRaceScheduled: new Howl({
        src: [
          '/sounds/notifications/notification-race-scheduled.aac',
          '/sounds/notifications/notification-race-scheduled.mp3'
        ],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.notificationRaceScheduled!
      }),
      notificationRaceReady: new Howl({
        src: [
          '/sounds/notifications/notification-race-ready.aac',
          '/sounds/notifications/notification-race-ready.mp3'
        ],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.notificationRaceReady!
      }),
      notificationRaceStarting: new Howl({
        src: [
          '/sounds/notifications/notification-race-starting.aac',
          '/sounds/notifications/notification-race-starting.mp3'
        ],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.notificationRaceStarting!
      }),
      notificationRaceStarted: new Howl({
        src: [
          '/sounds/notifications/notification-race-started.aac',
          '/sounds/notifications/notification-race-started.mp3'
        ],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.notificationRaceStarted!
      }),
      // We use this when the race has already started when a player is joining
      notificationRaceStartedAlt: new Howl({
        src: [
          '/sounds/notifications/notification-race-started_alt.aac',
          '/sounds/notifications/notification-race-started_alt.mp3'
        ],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.notificationRaceStartedAlt!
      }),
      notificationRaceEnded: new Howl({
        src: [
          '/sounds/notifications/notification-race-ended.aac',
          '/sounds/notifications/notification-race-ended.mp3'
        ],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.notificationRaceEnded!
      }),
      notificationRaceSettled: new Howl({
        src: [
          '/sounds/notifications/notification-race-settled.aac',
          '/sounds/notifications/notification-race-settled.mp3'
        ],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.notificationRaceSettled!
      }),
      notificationRaceSettledWon1: new Howl({
        src: [
          '/sounds/notifications/notification-race-settled_won_1.aac',
          '/sounds/notifications/notification-race-settled_won_1.mp3'
        ],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.notificationRaceSettledWon1!
      }),
      notificationRaceSettledLost1: new Howl({
        src: [
          '/sounds/notifications/notification-race-settled_lost_1.aac',
          '/sounds/notifications/notification-race-settled_lost_1.mp3'
        ],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.notificationRaceSettledLost1!
      }),
      notificationRaceSettledLost2: new Howl({
        src: [
          '/sounds/notifications/notification-race-settled_lost_2.aac',
          '/sounds/notifications/notification-race-settled_lost_2.mp3'
        ],
        volume: this.volume * SoundEffectsManager.VOLUME_MODIFIERS.notificationRaceSettledLost2!
      })
    }

    Object.entries(this.soundEffects).forEach(([name, sound]) => {
      const modifier = SoundEffectsManager.VOLUME_MODIFIERS[name as SoundEffectNames] ?? 1
      sound.volume(this.isMuted ? 0 : this.volume * modifier)
      sound.mute(this.isMuted)
    })

    // Set up visibility change listener
    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', this.handleVisibilityChange)
    }
  }

  private handleVisibilityChange = () => {
    const wasTabFocused = this.isTabFocused
    this.isTabFocused = !document.hidden

    // When coming back to tab, restore previous volume settings
    if (this.isTabFocused && !wasTabFocused) {
      Object.values(this.soundEffects).forEach((sound) => {
        sound.mute(this.isMuted)
        if (!this.isMuted) {
          sound.volume(this.volume)
        }
      })
    }
  }

  playSoundEffect(soundName: SoundEffectNames) {
    if (!this.isMuted && this.isTabFocused && this.soundEffects[soundName]) {
      this.soundEffects[soundName].play()
    }
  }

  setSoundEffectsVolume(volume: number) {
    this.volume = volume / 100
    Object.entries(this.soundEffects).forEach(([name, sound]) => {
      const modifier = SoundEffectsManager.VOLUME_MODIFIERS[name as SoundEffectNames] ?? 1
      sound.volume(this.volume * modifier)
    })
  }

  muteSoundEffects(mute: boolean) {
    this.isMuted = mute
    Object.values(this.soundEffects).forEach((sound) => {
      sound.mute(mute)
    })
  }

  getVolume(): number {
    return this.volume * 100
  }

  getMuted(): boolean {
    return this.isMuted
  }

  cleanup() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
    Object.values(this.soundEffects).forEach((sound) => {
      sound.stop()
    })
  }
}

const soundEffectsManager = new SoundEffectsManager()

// Exported functions that maintain your existing API
export const playSoundEffect = (soundName: SoundEffectNames) => {
  soundEffectsManager.playSoundEffect(soundName)
}

export const setSoundEffectsVolume = (volume: number) => {
  soundEffectsManager.setSoundEffectsVolume(volume)
}

export const muteSoundEffects = (mute: boolean) => {
  soundEffectsManager.muteSoundEffects(mute)
}

// Background music controls
export const setBackgroundMusicVolume = (volume: number) => {
  const soundSettings = SoundSettingsManager.getInstance()
  soundSettings.setVolume('backgroundMusic', volume)
  backgroundMusicManager.setVolume(volume)
}

export const muteBackgroundMusic = (mute: boolean) => {
  const soundSettings = SoundSettingsManager.getInstance()
  soundSettings.setMuted('music', mute)
  backgroundMusicManager.mute(mute)
}
export const playBackgroundMusic = () => {
  const soundSettings = SoundSettingsManager.getInstance()
  const settings = soundSettings.getSettings()

  // First update the volume
  backgroundMusicManager.setVolume(settings.volumes.backgroundMusic)

  // Update mute state
  const shouldBeMuted = settings.muted.master || settings.muted.music
  backgroundMusicManager.mute(shouldBeMuted)

  // Only try to play if we're not muted
  if (!shouldBeMuted) {
    backgroundMusicManager.play()
  }
}

export const stopBackgroundMusic = () => {
  backgroundMusicManager.pause()
}

export const pauseBackgroundMusic = () => {
  backgroundMusicManager.pause()
}

export const fadeOutBackgroundMusic = (duration = 1000) => {
  backgroundMusicManager.fadeOut(duration)
}

export const fadeInBackgroundMusic = (duration = 1000) => {
  backgroundMusicManager.fadeIn(duration)
}

export const getBackgroundMusicVolume = (): number => {
  return backgroundMusicManager.getVolume()
}

export const isBackgroundMusicMuted = (): boolean => {
  return backgroundMusicManager.getMuted()
}

export const getSoundEffectsVolume = (): number => {
  return soundEffectsManager.getVolume()
}

export const getIsSoundEffectsMuted = (): boolean => {
  return soundEffectsManager.getMuted()
}
