export const betTypeMap = {
  PickWinner: 'PickWinner',
  StraightForecast: 'StraightForecast',
  ReverseForecast: 'ReverseForecast',
  Tricast: 'Tricast',
  CombinationTricast: 'CombinationTricast'
} as const

export const betTypes = Object.values(betTypeMap)

export type BetTypeMap = typeof betTypeMap

export type BetType = BetTypeMap[BetTypeMap[keyof BetTypeMap]]

export interface PickWinnerDetails {
  type: BetTypeMap['PickWinner']
  selectedItems: string[]
}

// Straight Forecast: pick items which need to finish in that exact order.
export interface StraightForecastDetails {
  type: BetTypeMap['StraightForecast']
  selectedItems: [string, string]
}

// Reverse Forecast: pick items which can finish in any order.
export interface ReverseForecastDetails {
  type: BetTypeMap['ReverseForecast']
  selectedItems: [string, string]
}

// Tricast: pick items which need to finish in exact order
export interface TricastDetails {
  type: BetTypeMap['Tricast']
  selectedItems: [string, string, string]
}

// Combination Tricast: pick items which can finish in any order
export interface CombinationTricastDetails {
  type: BetTypeMap['CombinationTricast']
  selectedItems: [string, string, string]
}

export type BetDetails =
  | PickWinnerDetails
  | StraightForecastDetails
  | ReverseForecastDetails
  | TricastDetails
  | CombinationTricastDetails
