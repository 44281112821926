import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import type { Balance } from 'src/types/domain/Player'
import { isDevtoolsEnabled } from './utils/isDevtoolsEnabled'

interface DemoState {
  balance: Balance
}

export interface DemoActions {
  balanceChanged: (balance: Balance) => void
}

type DemoStore = DemoState & { actions: DemoActions }

export const useDemoStore = create<DemoStore>()(
  devtools(
    immer((set) => ({
      balance: {
        amount: 100,
        currency: 'XXX'
      },
      actions: {
        balanceChanged: (balance: Balance) => {
          set(
            (state) => {
              state.balance = balance
            },
            undefined,
            'balanceChanged'
          )
        }
      }
    })),
    { name: 'DemoStore', enabled: isDevtoolsEnabled() }
  )
)

export const useGetCurrentDemoBalance = () => useDemoStore((state) => state.balance)
