'use client';

import { useEffect, useState, useCallback } from 'react';
import BroadcastControls from 'src/components/BroadcastControls';
import { BalanceContainer } from './Balance/BalanceContainer';
import { ToolbarInfoContainer } from './ToolbarInfo/ToolbarInfoContainer';
import { Logo } from './Logo/Logo';
type Props = Readonly<{
  showLogo?: boolean;
  onToggleFullscreen?: () => void;
  videoSourceCount?: number;
  onToggleSidebar: () => void;
  isSidebarOpen: boolean;
  goToBack: () => void;
}>;
export function GameTopToolbar({
  showLogo = false,
  onToggleFullscreen,
  videoSourceCount,
  onToggleSidebar,
  isSidebarOpen,
  goToBack
}: Props) {
  const [gameTopToolbarStyle, setGameTopToolbarStyle] = useState({});
  const updateGameTopToolbarStyle = useCallback(() => {
    const viewportWidth = window.innerWidth;
    if (viewportWidth > 768) {
      const idealViewportWidth = 1120;
      const scaleFactor = Math.min(viewportWidth / idealViewportWidth, 1);
      setGameTopToolbarStyle({
        transform: `scale(${scaleFactor})`
      });
    } else {
      setGameTopToolbarStyle({});
    }
  }, []);
  useEffect(() => {
    updateGameTopToolbarStyle();
    window.addEventListener('resize', updateGameTopToolbarStyle);
    return () => {
      window.removeEventListener('resize', updateGameTopToolbarStyle);
    };
  }, [updateGameTopToolbarStyle]);
  return <div className="game-top-toolbar" data-sentry-component="GameTopToolbar" data-sentry-source-file="GameTopToolbar.tsx">
      <div className="game-top-toolbar__inner">
        {showLogo ? <Logo /> : null}
        <ToolbarInfoContainer gameTopToolbarStyle={gameTopToolbarStyle} goToBack={goToBack} data-sentry-element="ToolbarInfoContainer" data-sentry-source-file="GameTopToolbar.tsx" />
        <div className="game-top-toolbar__right" style={gameTopToolbarStyle}>
          <BroadcastControls onToggleFullscreen={onToggleFullscreen} videoSourceCount={videoSourceCount} data-sentry-element="BroadcastControls" data-sentry-source-file="GameTopToolbar.tsx" />

          <BalanceContainer isSidebarOpen={isSidebarOpen} onToggleSidebar={onToggleSidebar} data-sentry-element="BalanceContainer" data-sentry-source-file="GameTopToolbar.tsx" />
        </div>
      </div>
    </div>;
}