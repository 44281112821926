import { Brand } from 'effect'
import { z } from 'zod'

export type RaceEventRowDTO = {
  event_timestamp: string
  event_type: string
  id: string
  payload: {
    results: {
      marbleId: string
      position: number
      marbleColor: string
      marbleNumber: number
    }[]
  } | null
  race_configuration_id: string
  race_id: string
} & Brand.Brand<'RaceEventRowDTO'>

export const RaceEventRowDTORawSchema = z.object({
  event_timestamp: z.string(),
  event_type: z.string(),
  id: z.string(),
  payload: z
    .object({
      results: z.array(
        z.object({
          marbleId: z.string(),
          position: z.number(),
          marbleColor: z.string(),
          marbleNumber: z.number()
        })
      )
    })
    .nullable(),
  race_configuration_id: z.string(),
  race_id: z.string()
})

const brandToRaceEventRowDTO = Brand.nominal<RaceEventRowDTO>()

export const RaceEventRowDTOSchema = RaceEventRowDTORawSchema.transform(brandToRaceEventRowDTO)
