import { useSoundControls } from 'src/hooks/useSoundControl';
import { useClientSideTranslation, getI18nSettings } from 'src/services/i18n';
import { useGetCurrentPlayer } from 'src/store/player';
import AngleLeftIcon from 'src/app/assets/icons/ico-angle-left.svg';
import CogIcon from 'src/app/assets/icons/ico-cog.svg';
import { isAuthenticatedPlayer } from 'src/types/domain/Player';
import { useGameActions, useGetCurrentLanguage } from 'src/store/game';
import { VolumeControl } from '../VolumeControl';
type Props = Readonly<{
  // selectedCommentator: string
  // setSelectedCommentator: (commentator: string) => void
  showAvatar: boolean;
  setShowAvatar: (showAvatar: boolean) => void;
}>;
export function SettingsPanel({
  onBackClick
}: Props & {
  onBackClick: () => void;
}) {
  const currentLanguage = useGetCurrentLanguage();
  const {
    t
  } = useClientSideTranslation(currentLanguage);
  const soundControls = useSoundControls();
  const {
    state,
    controls
  } = soundControls;
  const player = useGetCurrentPlayer();
  const playerActions = useGameActions();

  // const commentators = [
  //   { id: 'Wayne_20240711', name: 'Wayne', image: '/ai-commentators/wayne.webp' },
  //   { id: '7c3086c7fc8440568a35903888ed1804', name: 'Alec', image: '/ai-commentators/alec.webp' },
  //   { id: '37f4d912aa564663a1cf8d63acd0e1ab', name: 'Sofia', image: '/ai-commentators/sofia.webp' },
  //   { id: '43c34c4285cb4b6c81856713c70ba23b', name: 'Aiden', image: '/ai-commentators/aiden.webp' },
  //   { id: 'fa7b34fe0b294f02b2fca6c1ed2c7158', name: 'Vicky', image: '/ai-commentators/vicky.webp' },
  //   { id: 'eb0a8cc8046f476da551a5559fbb5c82', name: 'Raj', image: '/ai-commentators/raj.webp' }
  // ]

  // const [localSelectedCommentator, setLocalSelectedCommentator] = useState(selectedCommentator)

  // const debouncedSetCommentator = useCallback(
  //   (() => {
  //     let timeoutId: NodeJS.Timeout | null = null
  //     return (commentatorId: string) => {
  //       if (timeoutId) {
  //         clearTimeout(timeoutId)
  //       }
  //       timeoutId = setTimeout(() => {
  //         setSelectedCommentator(commentatorId)
  //         timeoutId = null
  //       }, 1000) // 1s delay
  //     }
  //   })(),
  //   [setSelectedCommentator]
  // )

  // useEffect(() => {
  //   if (localSelectedCommentator !== selectedCommentator) {
  //     debouncedSetCommentator(localSelectedCommentator)
  //   }
  // }, [localSelectedCommentator, selectedCommentator, debouncedSetCommentator])

  // const handleNicknameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setNickname(e.target.value)
  // }

  return <div className="settings-panel" data-sentry-component="SettingsPanel" data-sentry-source-file="SettingsPanel.tsx">
      <div className="panel-header">
        <button className="back" onClick={onBackClick} type="button">
          <span className="icon">
            <AngleLeftIcon data-sentry-element="AngleLeftIcon" data-sentry-source-file="SettingsPanel.tsx" />
          </span>
        </button>
        <CogIcon data-sentry-element="CogIcon" data-sentry-source-file="SettingsPanel.tsx" />
        <span>{t('Settings')}</span>
      </div>
      <div className="panel-content">
        <h3>{t('Account')}</h3>
        <div className="panel-content_section">
          <label>
            Nickname
            <input disabled
          // onChange={handleNicknameChange}
          placeholder="Nickname" type="text" value={isAuthenticatedPlayer(player) ? player.username : t('Anonymous')} />
          </label>
        </div>

        <h3>{t('Audio')}</h3>
        <div className="panel-content_section">
          <VolumeControl isMuted={state.muted.master} label={t('Master')} setVolume={v => controls.setVolume('master', v)} toggleMute={() => controls.toggleMute('master')} volume={state.volumes.master} data-sentry-element="VolumeControl" data-sentry-source-file="SettingsPanel.tsx" />
          <VolumeControl isMuted={state.muted.raceSounds} label={t('Live Broadcast')} setVolume={v => controls.setVolume('raceSounds', v)} toggleMute={() => controls.toggleMute('raceSounds')} volume={state.volumes.raceSounds} data-sentry-element="VolumeControl" data-sentry-source-file="SettingsPanel.tsx" />
          <VolumeControl isMuted={state.muted.commentator} label={t('Commentator')} setVolume={v => controls.setVolume('commentator', v)} toggleMute={() => controls.toggleMute('commentator')} volume={state.volumes.commentator} data-sentry-element="VolumeControl" data-sentry-source-file="SettingsPanel.tsx" />
          <VolumeControl isMuted={state.muted.soundEffects} label={t('Sound Effects')} setVolume={v => controls.setVolume('soundEffects', v)} toggleMute={() => controls.toggleMute('soundEffects')} volume={state.volumes.soundEffects} data-sentry-element="VolumeControl" data-sentry-source-file="SettingsPanel.tsx" />
          <VolumeControl isMuted={state.muted.music} label={t('Music')} setVolume={v => controls.setVolume('backgroundMusic', v)} toggleMute={() => controls.toggleMute('music')} volume={state.volumes.backgroundMusic} data-sentry-element="VolumeControl" data-sentry-source-file="SettingsPanel.tsx" />
        </div>

        {/* <h3>{t('AI Commentators')}</h3>
         <div className="panel-content_section commentator-selector">
          <div className="commentators-selector">
            {commentators.map((commentator) => (
              <button
                className={localSelectedCommentator === commentator.id ? 'selected' : ''}
                key={commentator.id}
                onClick={() => setLocalSelectedCommentator(commentator.id)}
                type="button"
              >
                <span>{commentator.name}</span>
                <Image
                  alt={commentator.name}
                  draggable="false"
                  height={80}
                  src={commentator.image}
                  width={80}
                />
              </button>
            ))}
          </div>
         </div> */}

        <div className="panel-content_section">
          {/* <label>
            {t('Show avatar')}
            <button
              className={`inline-btn btn--switch ${showAvatar ? 'green-active' : ''}`}
              onClick={() => setShowAvatar(true)}
              type="button"
            >
              <span>Yes</span>
            </button>
            <button
              className={`inline-btn btn--switch ${!showAvatar ? 'red-active' : ''}`}
              onClick={() => setShowAvatar(false)}
              type="button"
            >
              <span>No</span>
            </button>
           </label> */}
          <label>
            {t('Language')}
            <select onChange={e => {
            playerActions.setCurrentLanguage(e.target.value);
          }} value={currentLanguage}>
              {getI18nSettings().supportedLngs.map(lang => <option key={lang} value={lang}>
                  {t(lang)}
                </option>)}
            </select>
          </label>
        </div>
      </div>
    </div>;
}