import type { BetDetails } from 'src/types/domain/BetDetails'

export const betStatusDTOMap = {
  WON: 'won',
  LOST: 'lost',
  PENDING: 'pending',
  VOIDED: 'voided'
} as const

export type BetStatusDTO = (typeof betStatusDTOMap)[keyof typeof betStatusDTOMap]

export interface BalanceDTO {
  amount: number
  currency: string
  playerId: string
}

export type BetDetailsDTO = BetDetails

export interface BetRejectedDTO {
  id: string
  playerId: string
  gameId: string
  roundId: string
  currency: string
  receivedAt: string
  amount: number
  details: BetDetailsDTO
}

export interface BetDTO {
  id: string
  playerId: string
  gameId: string
  roundId: string
  amount: number
  // externalSource: string
  details: BetDetailsDTO
  status: BetStatusDTO
  potentialPayout: number
  currency: string
  createdAt: string
}
export interface BetResponseDTO {
  balance: BalanceDTO
  bet: BetDTO
}

export interface PlaceBetRequestedDTO {
  id: string
  gameId: string
  roundId: string
  playerId: string
  sessionId: string
  amount: number
  currency: string
  details: BetDetails
}

export interface CalculatePayoutDTO {
  gameId: string
  betAmount: number
  betDetails: BetDetails
}

export interface PlayerDTO {
  id: string
  token: string
  sessionId: string
  displayName: string
  avatar: string // TODO: add avatar
  funds: number
}

export interface TokenGameConfigDTO {
  externalLobbyUrl?: string
  externalDepositUrl?: string
  platform: 'MOBILE' | 'DESKTOP'
  language?: string
  country?: string
}

export interface GameConfigDTO {
  bet: {
    currency: string
    limits: {
      PickWinner: {
        min: number
        max: number
      }
      ReverseForecast: {
        min: number
        max: number
      }
      StraightForecast: {
        min: number
        max: number
      }
      CombinationTricast: {
        min: number
        max: number
      }
      Tricast: {
        min: number
        max: number
      }
    }
  }
}
