import { SoundSettingsManager } from './soundSettingManager'
import { Howl } from 'howler'

export class BackgroundMusicManager {
  private static instance: BackgroundMusicManager | null = null
  private static hasUserInteracted = false

  private melodies: Howl[] = []
  private currentMusic!: Howl
  private isPlaying = false
  private currentMelodyIndex = 0
  private volume = 0.05
  private isMuted = false
  private fadeTimeout: NodeJS.Timeout | null = null
  private isTabFocused = true
  private pendingPlay = false

  public static getInstance(): BackgroundMusicManager {
    if (!BackgroundMusicManager.instance) {
      BackgroundMusicManager.instance = new BackgroundMusicManager()
    }
    return BackgroundMusicManager.instance
  }

  private static userInteractionHandler = () => {
    BackgroundMusicManager.hasUserInteracted = true
    window.removeEventListener('click', BackgroundMusicManager.userInteractionHandler)
    window.removeEventListener('keydown', BackgroundMusicManager.userInteractionHandler)
    window.removeEventListener('touchstart', BackgroundMusicManager.userInteractionHandler)

    const soundSettings = SoundSettingsManager.getInstance()
    const settings = soundSettings.getSettings()

    const instance = BackgroundMusicManager.getInstance()
    if (instance.pendingPlay && !settings.muted.master && !settings.muted.music) {
      instance.play()
    }
  }

  private constructor() {
    if (typeof window === 'undefined') return

    // Load initial state from settings
    const soundSettings = SoundSettingsManager.getInstance()
    const settings = soundSettings.getSettings()

    // Set initial states
    this.volume = settings.volumes.backgroundMusic / 100
    this.isMuted = settings.muted.master || settings.muted.music
    this.isTabFocused = !document.hidden
    this.pendingPlay = false
    this.isPlaying = false

    // Initialize all melodies
    this.melodies = [
      new Howl({
        src: ['/sounds/songs/background-melody-01.aac', '/sounds/songs/background-melody-01.mp3'],
        loop: false,
        volume: 0,
        autoplay: false,
        mute: this.isMuted,
        onend: () => this.handleMelodyEnd()
      }),
      new Howl({
        src: ['/sounds/songs/background-melody-02.aac', '/sounds/songs/background-melody-02.mp3'],
        loop: false,
        volume: 0,
        autoplay: false,
        mute: this.isMuted,
        onend: () => this.handleMelodyEnd()
      }),
      new Howl({
        src: ['/sounds/songs/background-melody-03.aac', '/sounds/songs/background-melody-03.mp3'],
        loop: false,
        volume: 0,
        autoplay: false,
        mute: this.isMuted,
        onend: () => this.handleMelodyEnd()
      }),
      new Howl({
        src: ['/sounds/songs/background-melody-04.aac', '/sounds/songs/background-melody-04.mp3'],
        loop: false,
        volume: 0,
        autoplay: false,
        mute: this.isMuted,
        onend: () => this.handleMelodyEnd()
      }),
      new Howl({
        src: ['/sounds/songs/background-melody-05.aac', '/sounds/songs/background-melody-05.mp3'],
        loop: false,
        volume: 0,
        autoplay: false,
        mute: this.isMuted,
        onend: () => this.handleMelodyEnd()
      })
    ]

    // Set random initial melody
    this.currentMelodyIndex = Math.floor(Math.random() * this.melodies.length)
    this.currentMusic = this.melodies[this.currentMelodyIndex]!

    // Set up event listeners
    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', this.handleVisibilityChange)
      window.addEventListener('blur', this.handleBlur)
      window.addEventListener('focus', this.handleFocus)
    }

    // Set up user interaction handlers
    if (!BackgroundMusicManager.hasUserInteracted) {
      window.addEventListener('click', BackgroundMusicManager.userInteractionHandler)
      window.addEventListener('keydown', BackgroundMusicManager.userInteractionHandler)
      window.addEventListener('touchstart', BackgroundMusicManager.userInteractionHandler)
    }
  }

  private handleBlur = () => {
    if (this.isPlaying) {
      this.fadeOut(300)
    }
  }

  private handleFocus = () => {
    if (this.pendingPlay || (this.isPlaying && !this.isMuted)) {
      setTimeout(() => {
        this.fadeIn(1000)
        this.pendingPlay = false
      }, 100)
    }
  }

  private handleVisibilityChange = () => {
    const wasTabFocused = this.isTabFocused
    this.isTabFocused = !document.hidden

    if (this.isTabFocused && !wasTabFocused) {
      if ((this.pendingPlay || this.isPlaying) && !this.isMuted) {
        setTimeout(() => {
          this.fadeIn(1000)
          this.pendingPlay = false
        }, 100)
      }
    } else if (!this.isTabFocused && wasTabFocused) {
      if (this.isPlaying) {
        this.fadeOut(300)
      }
    }
  }

  private handleMelodyEnd = () => {
    if (!this.isPlaying) return

    // Move to next melody index, wrapping around to 0 when reaching the end
    this.currentMelodyIndex = (this.currentMelodyIndex + 1) % this.melodies.length
    const nextMusic = this.melodies[this.currentMelodyIndex]! // Add non-null assertion

    if (!this.isMuted && this.isTabFocused) {
      nextMusic.volume(this.volume)
      nextMusic.mute(false)
      nextMusic.play()
      this.currentMusic = nextMusic
    }
  }

  play() {
    const soundSettings = SoundSettingsManager.getInstance()
    const settings = soundSettings.getSettings()

    this.isMuted = settings.muted.master || settings.muted.music
    this.volume = settings.volumes.backgroundMusic / 100

    if (this.isMuted) {
      this.pendingPlay = false
      return
    }

    if (!BackgroundMusicManager.hasUserInteracted) {
      this.pendingPlay = true
      return
    }

    if (!this.isTabFocused) {
      this.pendingPlay = true
      return
    }

    if (!this.isPlaying) {
      this.currentMusic.stop()
      this.currentMusic.mute(this.isMuted)
      this.currentMusic.volume(this.volume)

      if (!this.isMuted && this.isTabFocused) {
        this.isPlaying = true
        this.currentMusic.play()
      }

      this.pendingPlay = false
    }
  }

  pause() {
    if (this.isPlaying) {
      this.isPlaying = false
      if (this.fadeTimeout) {
        clearTimeout(this.fadeTimeout)
      }
      this.currentMusic.stop()
    }
  }

  fadeIn(duration = 1000) {
    if (!this.isTabFocused || this.isMuted) return

    this.isPlaying = true
    const targetVolume = this.volume

    if (this.fadeTimeout) {
      clearTimeout(this.fadeTimeout)
      this.fadeTimeout = null
    }

    if (this.currentMusic.playing()) {
      this.currentMusic.fade(0, targetVolume, duration)
    } else {
      this.currentMusic.stop()
      this.currentMusic.volume(0)
      this.currentMusic.mute(this.isMuted)
      this.currentMusic.play()
      this.currentMusic.fade(0, targetVolume, duration)
    }
  }

  fadeOut(duration = 1000) {
    if (this.isPlaying) {
      const currentVolume = this.isMuted ? 0 : this.volume

      if (this.fadeTimeout) {
        clearTimeout(this.fadeTimeout)
      }

      this.currentMusic.fade(currentVolume, 0, duration)
      this.fadeTimeout = setTimeout(() => {
        this.currentMusic.volume(0)
        if (this.isMuted) {
          this.currentMusic.mute(true)
        }
        this.fadeTimeout = null
      }, duration)
    }
  }

  setVolume(volume: number) {
    this.volume = volume / 100
    if (!this.isMuted && this.isTabFocused && this.isPlaying) {
      this.currentMusic.volume(this.volume)
    }
  }

  mute(muted: boolean) {
    this.isMuted = muted

    if (this.isPlaying) {
      this.currentMusic.mute(muted)
      if (muted) {
        this.fadeOut(300)
        this.isPlaying = false
      } else {
        const settings = SoundSettingsManager.getInstance().getSettings()
        if (!settings.muted.master) {
          this.fadeIn(1000)
        }
      }
    } else if (!muted) {
      const settings = SoundSettingsManager.getInstance().getSettings()
      if (!settings.muted.master) {
        this.play()
      }
    }
  }

  getVolume(): number {
    return this.volume * 100
  }

  getMuted(): boolean {
    return this.isMuted
  }

  cleanup() {
    if (this.fadeTimeout) {
      clearTimeout(this.fadeTimeout)
    }
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
    window.removeEventListener('blur', this.handleBlur)
    window.removeEventListener('focus', this.handleFocus)
    window.removeEventListener('click', BackgroundMusicManager.userInteractionHandler)
    window.removeEventListener('keydown', BackgroundMusicManager.userInteractionHandler)
    window.removeEventListener('touchstart', BackgroundMusicManager.userInteractionHandler)
    this.pause()
  }
}

export const backgroundMusicManager = BackgroundMusicManager.getInstance()
