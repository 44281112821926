import { useEffect, useState } from 'react';
import { fromInteger } from '@heathmont/money';
import type { BetType } from 'src/types/domain/BetDetails';
import { getCurrencySymbol } from 'src/utils/getCurrencySymbol';
import { isAuthenticatedPlayer } from 'src/types/domain/Player';
import { useGetCurrentPlayer } from 'src/store/player';
import { useGetCurrentGame, useGetCurrentLanguage, useGetGameBetConfig } from 'src/store/game';
import { isMarbleGame, isUnknownGame } from 'src/types/domain/Game';
import { getAllOdds } from 'src/actions/getAllOdds';
import { useClientSideTranslation } from 'src/services/i18n';
import RandomIcon from 'src/app/assets/icons/ico-random.svg';
import BullseyeIcon from 'src/app/assets/icons/ico-bullseye.svg';
import { displayAmount } from 'src/utils/displayAmount';
export default function OddsTable() {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const [odds, setOdds] = useState<Record<BetType, {
    odds: number;
  }>>();
  const player = useGetCurrentPlayer();
  const game = useGetCurrentGame();
  const betConfig = useGetGameBetConfig(player);
  const totalMarbles = isMarbleGame(game) ? game.totalMarbles : undefined;
  useEffect(() => {
    if (!totalMarbles) {
      return;
    }
    const getOdds = async () => {
      const odds = await getAllOdds({
        totalItemsSelected: 1,
        totalItems: totalMarbles
      });
      setOdds(odds);
    };
    void getOdds();
  }, [totalMarbles]);
  if (isUnknownGame(game) || !isAuthenticatedPlayer(player) || !odds) {
    return null;
  }
  const currentCurrency = getCurrencySymbol(player.balance.currency);
  if (!betConfig) {
    return null;
  }
  return <div className="toolbar-info__dropdown__table" data-sentry-component="OddsTable" data-sentry-source-file="OddsTable.tsx">
      <div className="header">
        <span>{t('Name')}</span>
        <span>{t('Odds table')}</span>
        <span>{t('Min bet')}</span>
        <span>{t('Max bet')}</span>
      </div>
      <div className="body">
        <div className="row">
          <div className="label">
            <div className="line">
              <span className="circle yellow" />
              <span className="yellow">{t('1 Winner')}</span>
            </div>
          </div>
          <span>{odds.PickWinner.odds}:1</span>
          <span>
            {currentCurrency}{' '}
            {displayAmount(fromInteger(betConfig.limits.PickWinner.min, player.balance.currency), true)}
          </span>
          <span>
            {currentCurrency}{' '}
            {displayAmount(fromInteger(betConfig.limits.PickWinner.max, player.balance.currency), true)}
          </span>
        </div>
        <div className="row">
          <div className="label">
            <div className="line">
              <span className="circle tomato" />
              <span className="circle tomato" />
              <span className="tomato">{t('2 Marbles')}</span>
            </div>
            <div className="line">
              <RandomIcon className="tomato" data-sentry-element="RandomIcon" data-sentry-source-file="OddsTable.tsx" /> <span>{t('Any order')}</span>
            </div>
          </div>
          <span>{odds.ReverseForecast.odds}:1</span>
          <span>
            {currentCurrency}{' '}
            {displayAmount(fromInteger(betConfig.limits.ReverseForecast.min, player.balance.currency), true)}
          </span>
          <span>
            {currentCurrency}{' '}
            {displayAmount(fromInteger(betConfig.limits.ReverseForecast.max, player.balance.currency), true)}
          </span>
        </div>
        <div className="row">
          <div className="label">
            <div className="line">
              <span className="circle tomato" />
              <span className="circle tomato" />
              <span className="tomato">{t('2 Marbles')}</span>
            </div>
            <div className="line">
              <BullseyeIcon className="tomato" data-sentry-element="BullseyeIcon" data-sentry-source-file="OddsTable.tsx" /> <span>{t('Exact order')}</span>
            </div>
          </div>
          <span>{odds.StraightForecast.odds}:1</span>
          <span>
            {currentCurrency}{' '}
            {displayAmount(fromInteger(betConfig.limits.StraightForecast.min, player.balance.currency), true)}
          </span>
          <span>
            {currentCurrency}{' '}
            {displayAmount(fromInteger(betConfig.limits.StraightForecast.max, player.balance.currency), true)}
          </span>
        </div>
        <div className="row">
          <div className="label">
            <div className="line">
              <span className="circle purple" />
              <span className="circle purple" />
              <span className="circle purple" />
              <span className="purple">{t('3 Marbles')}</span>
            </div>
            <div className="line">
              <RandomIcon className="purple" data-sentry-element="RandomIcon" data-sentry-source-file="OddsTable.tsx" /> <span>{t('Any order')}</span>
            </div>
          </div>
          <span>{odds.CombinationTricast.odds}:1</span>
          <span>
            {currentCurrency}{' '}
            {displayAmount(fromInteger(betConfig.limits.CombinationTricast.min, player.balance.currency), true)}
          </span>
          <span>
            {currentCurrency}{' '}
            {displayAmount(fromInteger(betConfig.limits.CombinationTricast.max, player.balance.currency), true)}
          </span>
        </div>
        <div className="row">
          <div className="label">
            <div className="line">
              <span className="circle purple" />
              <span className="circle purple" />
              <span className="circle purple" />
              <span className="purple">{t('3 Marbles')}</span>
            </div>
            <div className="line">
              <BullseyeIcon className="purple" data-sentry-element="BullseyeIcon" data-sentry-source-file="OddsTable.tsx" /> <span>{t('Exact order')}</span>
            </div>
          </div>
          <span>{odds.Tricast.odds}:1</span>
          <span>
            {currentCurrency}{' '}
            {displayAmount(fromInteger(betConfig.limits.Tricast.min, player.balance.currency), true)}
          </span>
          <span>
            {currentCurrency}{' '}
            {displayAmount(fromInteger(betConfig.limits.Tricast.max, player.balance.currency), true)}
          </span>
        </div>
      </div>
    </div>;
}