'use client'
import { Option } from 'effect'
import { useCallback } from 'react'
import * as actions from 'src/actions'
import { type AuthenticatedPlayer, type Balance } from 'src/types/domain/Player'
import { getValidDecodedToken } from 'src/utils/getValidDecodedToken'
import {
  useGetCurrentPlayerToken,
  useIsAuthenticated,
  useIsDemoMode,
  usePlayerActions
} from 'src/store/player'
import type { PlayerDTO } from 'src/actions/types'
import { useGetCurrentDemoBalance } from 'src/store/demo'

export function usePlayerAPI() {
  const isAuthenticated = useIsAuthenticated()
  const playerActions = usePlayerActions()
  const tokenFromState = useGetCurrentPlayerToken()
  const isDemoMode = useIsDemoMode()
  const currentDemoBalance = useGetCurrentDemoBalance()

  const fetchBalance = useCallback(
    async (token?: string): Promise<Option.Option<Balance>> => {
      if (!token) {
        return Option.none()
      }

      let balanceResponse
      if (!isDemoMode) {
        balanceResponse = await actions.getBalance({ token })

        if ('error' in balanceResponse) {
          console.error('🚀 ~ fetchCurrentPlayer ~ balanceResponse.error:', balanceResponse.error)
          return Option.none()
        }
      } else {
        balanceResponse = {
          data: currentDemoBalance
        }
      }

      const balance: Balance = {
        amount: balanceResponse.data.amount,
        currency: balanceResponse.data.currency
      }

      return Option.some(balance)
    },
    [isDemoMode, currentDemoBalance]
  )

  const fetchCurrentPlayer = useCallback(
    async (args?: { token?: string }) => {
      const { token = tokenFromState } = args ?? {}

      const playerResponse = !token
        ? await actions.getCurrentPlayer()
        : getCurrentPlayerFromToken(token)

      if (!playerResponse) {
        console.error('Player not authenticated')
        playerActions.notAuthenticated()
        return
      }

      const balanceOpt = await fetchBalance(playerResponse.token)

      if (Option.isNone(balanceOpt)) {
        console.error('Balance not found')
        playerActions.notAuthenticated()
        return
      }

      const player: AuthenticatedPlayer = {
        type: 'AuthenticatedPlayer',
        id: playerResponse.id,
        token: playerResponse.token,
        sessionId: playerResponse.sessionId,
        username: playerResponse.displayName,
        avatar: '',
        balance: balanceOpt.value
      }
      playerActions.authenticated(player)
    },
    [playerActions, fetchBalance, tokenFromState]
  )

  const updateBalance = useCallback(async () => {
    if (isAuthenticated) {
      const balanceOpt = await fetchBalance(tokenFromState)

      if (Option.isNone(balanceOpt)) {
        console.error('Balance not found')
        return
      }

      playerActions.balanceChanged(balanceOpt.value)
    }
  }, [playerActions, fetchBalance, isAuthenticated, tokenFromState])

  return {
    fetchCurrentPlayer,
    updateBalance
  }
}

const getCurrentPlayerFromToken = (token: string): PlayerDTO | undefined => {
  const data = getValidDecodedToken(token)

  if (!data) {
    return undefined
  }

  return {
    id: data.externalId,
    token,
    sessionId: data.payload.sessionId,
    displayName: data.displayName,
    avatar: '',
    funds: 0
  }
}
