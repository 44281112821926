'use client';

import { useEffect, useState, useRef, useCallback } from 'react';
import WalletIcon from 'src/app/assets/icons/ico-wallet.svg';
import BarsIcon from 'src/app/assets/icons/ico-bars.svg';
import { displayAmount } from 'src/utils/displayAmount';
type Props = Readonly<{
  onToggleSidebar: () => void;
  isSidebarOpen: boolean;
  currentCurrency: string;
  currentBalance: number;
  initialBalance: number;
  afterAnimationCallback: () => void;
  avatar?: string;
}>;
export function Balance({
  onToggleSidebar,
  isSidebarOpen,
  currentCurrency,
  currentBalance,
  initialBalance,
  afterAnimationCallback,
  avatar
}: Props) {
  const [displayBalance, setDisplayBalance] = useState(currentBalance);
  const [prevBalance, setPrevBalance] = useState(initialBalance);
  const [isBalanceUpdated, setIsBalanceUpdated] = useState('');
  const balanceRef = useRef<HTMLSpanElement>(null);
  const animationRef = useRef<number>();
  const animateBalance = useCallback(() => {
    const duration = 1000; // Animation duration in milliseconds
    const start = performance.now();
    const startBalance = prevBalance;
    const endBalance = currentBalance;
    const step = (timestamp: number) => {
      const progress = Math.min((timestamp - start) / duration, 1);
      const currentValue = startBalance + (endBalance - startBalance) * progress;
      setDisplayBalance(currentValue);
      if (progress < 1) {
        animationRef.current = requestAnimationFrame(step);
      } else {
        setDisplayBalance(endBalance);
      }
    };
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
    animationRef.current = requestAnimationFrame(step);
    afterAnimationCallback();
  }, [prevBalance, currentBalance, afterAnimationCallback]);
  useEffect(() => {
    if (currentBalance !== prevBalance) {
      setIsBalanceUpdated(currentBalance > prevBalance ? 'positive' : 'negative');
      setPrevBalance(currentBalance);
      animateBalance();
    }
  }, [currentBalance, prevBalance, animateBalance]);
  useEffect(() => {
    if (isBalanceUpdated) {
      const balanceElement = balanceRef.current;
      if (balanceElement) {
        balanceElement.classList.add(`${isBalanceUpdated}`);
        setTimeout(() => {
          balanceElement.classList.remove('positive', 'negative', 'bet-placed');
          setIsBalanceUpdated('');
        }, 2000);
      }
    }
  }, [isBalanceUpdated]);
  return <div className="toolbar-player" data-sentry-component="Balance" data-sentry-source-file="Balance.tsx">
      <div className={`toolbar-button ${isSidebarOpen ? 'active' : ''}`} onClick={onToggleSidebar} onKeyDown={e => {
      if (e.key === 'Enter') {
        onToggleSidebar();
      }
    }} role="button" tabIndex={0}>
        <div className="player-balance">
          <div className="inner">
            <span className="label">
              <WalletIcon data-sentry-element="WalletIcon" data-sentry-source-file="Balance.tsx" />
              Balance
            </span>
            <span className="value" ref={balanceRef}>
              {currentCurrency}
              {displayAmount(displayBalance.toString(), true)}
            </span>
          </div>
        </div>

        {avatar ? <div className={`avatar ${avatar}`} /> : null}
        <span className="hamburger-icon">
          <BarsIcon data-sentry-element="BarsIcon" data-sentry-source-file="Balance.tsx" />
        </span>
      </div>
    </div>;
}