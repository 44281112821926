import type { NormalizedRace } from 'src/store/types'
import type { RaceDTO } from 'src/services/supabase/queries'
import type { Race } from '../../domain/Race'
import type { Tables } from '../../infra/supabase/database.types'
import { RaceEventMap } from '../RaceEventMap'

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export const fromRaceDTO = (race: RaceDTO): Race => {
  return {
    uuid: race.id,
    createdAt: race.created_at,
    round: race.round,
    raceConfigurationId: race.race_configuration_id,
    raceEvents: race.race_event.map(RaceEventMap.fromRaceEventRowDTO)
  }
}

export const fromRaceRowDTO = (
  raceRow: Tables<'race'>,
  raceEventIds?: string[]
): NormalizedRace => {
  return {
    id: raceRow.id,
    round: raceRow.round,
    createdAt: raceRow.created_at,
    raceConfigurationId: raceRow.race_configuration_id,
    raceEventIds: raceEventIds ?? []
  }
}

// export const toNormalizedRace = (race: Race): NormalizedRace => {
//   return {
//     ...race,
//     raceEventIds: race.raceEvents.map((event) => event.uuid),
//   }
// }

// import type { NormalizedMarbleGame } from 'src/app/store/types'
// import type { Tables } from '../../infra/supabase/database.types'

// export const fromGameRowDTO = (
//   gameRow: Tables<'race_configuration'>
// ): Partial<NormalizedMarbleGame> & { id: string; type: string } => {
//   return {
//     id: gameRow.id,
//     name: gameRow.name,
//     laps: gameRow.laps,
//     maxPickableMarbles: gameRow.max_pickable_marbles,
//     totalMarbles: gameRow.total_marbles,
//     active: gameRow.active,
//     trackId: gameRow.track_id,
//     createdAt: gameRow.created_at,
//     previewThumbnail: gameRow.preview_thumbnail ?? undefined,
//     type: 'MarbleGame'
//   }
// }
