import { isUnknownGame } from 'src/types/domain/Game';
import { isAuthenticatedPlayer } from 'src/types/domain/Player';
import { useGetCurrentGame } from 'src/store/game';
import { useGetCurrentPlayer } from 'src/store/player';
import { ToolbarInfo } from './ToolbarInfo';
type Props = Readonly<{
  gameTopToolbarStyle: React.CSSProperties;
  goToBack: () => void;
}>;
export function ToolbarInfoContainer({
  gameTopToolbarStyle,
  goToBack
}: Props) {
  const player = useGetCurrentPlayer();
  const game = useGetCurrentGame();
  if (isUnknownGame(game) || !isAuthenticatedPlayer(player)) {
    return null;
  }
  return <ToolbarInfo game={game} gameTopToolbarStyle={gameTopToolbarStyle} goToBack={goToBack} data-sentry-element="ToolbarInfo" data-sentry-component="ToolbarInfoContainer" data-sentry-source-file="ToolbarInfoContainer.tsx" />;
}