import { formatNumber } from "./formatNumber"

export function displayAmount(amount: string | number, format?: boolean): string {
  const numValue = typeof amount === 'string' ? Number(amount) : amount

  const factor = Math.pow(10, 2)
  const result = Math.trunc(numValue * factor) / factor

  return format ? formatNumber(result) : result.toFixed(2)
}
