import { useGetActiveTab, useGetIsSidebarOpen, useUIActions } from 'src/store/ui';
import type { MarbleGame } from 'src/types/domain/Game';
import { playSoundEffect } from 'src/utils/soundEffects';
import { useGetCurrentPlayer } from 'src/store/player';
import RightSidebar from './RightSidebar';
type Props = Readonly<{
  activeTab?: string | null;
  // selectedCommentator: string
  // setSelectedCommentator: (commentator: string) => void
  showAvatar: boolean;
  setShowAvatar: (showAvatar: boolean) => void;
  showStatsAndRules: boolean;
  game?: MarbleGame;
}>;
export function RightSidebarContainer({
  // selectedCommentator,
  // setSelectedCommentator,
  showAvatar,
  setShowAvatar,
  showStatsAndRules,
  game
}: Props) {
  const player = useGetCurrentPlayer();
  const {
    sidebarOpened
  } = useUIActions();
  const isSidebarOpen = useGetIsSidebarOpen();
  const activePanel = useGetActiveTab();
  const uiStateActions = useUIActions();
  const handleCloseSidebar = () => {
    sidebarOpened(false);
    playSoundEffect('click');
  };
  return <RightSidebar activePanel={activePanel} game={game} isOpen={isSidebarOpen} onClose={handleCloseSidebar} player={player}
  // selectedCommentator={selectedCommentator}
  setActivePanel={uiStateActions.tabActivated}
  // setSelectedCommentator={setSelectedCommentator}
  setShowAvatar={setShowAvatar} showAvatar={showAvatar} showStatsAndRules={showStatsAndRules} data-sentry-element="RightSidebar" data-sentry-component="RightSidebarContainer" data-sentry-source-file="RightSidebarContainer.tsx" />;
}