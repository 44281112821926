import { Brand } from 'effect'
import { z } from 'zod'

export type RaceRowDTO = {
  created_at: string
  id: string
  round: number
  race_configuration_id: string
} & Brand.Brand<'RaceRowDTO'>

export const RaceRowDTORawSchema = z.object({
  created_at: z.string(),
  id: z.string(),
  round: z.number(),
  race_configuration_id: z.string()
})

export const brandToRaceRowDTO = Brand.nominal<RaceRowDTO>()

export const RaceRowDTOSchema = RaceRowDTORawSchema.transform(brandToRaceRowDTO)
