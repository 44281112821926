import { create } from 'zustand'
import { setAutoFreeze } from 'immer'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { pipe, Array as A, Option as O, Struct } from 'effect'
import { omit } from 'effect/Struct'
import type { Bet } from 'src/types/domain/Bet'
import { betStatusMap } from 'src/types/domain/BetStatus'
import { isDevtoolsEnabled } from './utils/isDevtoolsEnabled'

setAutoFreeze(false)

interface BetState {
  unacceptedBets: Record<string, Bet>
  bets: {
    byId: Record<string, Bet>
    allIds: string[]
  }
}

export interface BetActions {
  betPlaced: (bet: Bet) => void
  betRejected: (betId: string) => void
  betAccepted: (bet: Bet) => void
  betUnaccepted: (bet: Bet) => void
  setBets: (bets: Bet[]) => void
  betWon: (bet: Bet) => void
  betLost: (bet: Bet) => void
  betCancelled: (bet: Bet) => void
  betVoided: (bet: Bet) => void
  getBetByRaceId: (raceId: string) => Bet | undefined
}

type BetStore = BetState & { actions: BetActions }

export const useBetStore = create<BetStore>()(
  devtools(
    immer(
      subscribeWithSelector((set, get) => ({
        unacceptedBets: {},
        bets: {
          byId: {},
          allIds: []
        },
        actions: {
          betPlaced: (bet: Bet) => {
            set(
              (state) => {
                state.bets.byId[bet.id] = bet

                if (!state.bets.allIds.includes(bet.id)) {
                  state.bets.allIds.push(bet.id)
                }

                // Wait for server response
                state.unacceptedBets[bet.id] = bet
              },
              undefined,
              { type: 'betPlaced', bet } as any
            )
          },
          betRejected: (betId: string) => {
            set(
              (state) => {
                state.bets.byId = omit(state.bets.byId, betId)
                state.bets.allIds = state.bets.allIds.filter((id) => id !== betId)

                // Response received from server
                state.unacceptedBets = omit(state.unacceptedBets, betId)
              },
              undefined,
              { type: 'betRejected', betId } as any
            )
          },
          setBets: (bets: Bet[]) => {
            set(
              (state) => {
                state.bets.byId = bets.reduce<Record<string, Bet>>((acc, bet) => {
                  acc[bet.id] = bet
                  return acc
                }, state.bets.byId)

                state.bets.allIds = bets.map((bet) => bet.id)
              },
              undefined,
              { type: 'setBets', bets } as any
            )
          },
          getBetByRaceId: (raceId: string) => {
            const state = get()
            const betId = state.bets.allIds.find((id) => state.bets.byId[id]?.raceId === raceId)
            return betId ? state.bets.byId[betId] : undefined
          },
          betAccepted: (bet: Bet) => {
            set(
              (state) => {
                state.bets.byId[bet.id] = bet

                if (!state.bets.allIds.includes(bet.id)) {
                  state.bets.allIds.push(bet.id)
                }

                // Response received from server
                state.unacceptedBets = omit(state.unacceptedBets, bet.id)
              },
              undefined,
              { type: 'betAccepted', bet } as any
            )
          },
          betUnaccepted: (bet: Bet) => {
            set(
              (state) => {
                state.bets.byId = omit(state.bets.byId, bet.id)
                state.bets.allIds = state.bets.allIds.filter((id) => id !== bet.id)
                state.unacceptedBets = omit(state.unacceptedBets, bet.id)
              },
              undefined,
              { type: 'betUnaccepted', bet } as any
            )
          },
          betWon: (bet: Bet) => {
            set(
              (state) => {
                const betFromState = state.bets.byId[bet.id]

                if (betFromState) {
                  betFromState.status = betStatusMap.WON
                }
              },
              undefined,
              { type: 'betWon', bet } as any
            )
          },
          betLost: (bet: Bet) => {
            set(
              (state) => {
                const betFromState = state.bets.byId[bet.id]

                if (betFromState) {
                  betFromState.status = betStatusMap.LOST
                }
              },
              undefined,
              { type: 'betLost', bet } as any
            )
          },
          betCancelled: (bet: Bet) => {
            set(
              (state) => {
                const betFromState = state.bets.byId[bet.id]

                if (betFromState) {
                  betFromState.status = betStatusMap.CANCELLED
                }
              },
              undefined,
              { type: 'betCancelled', bet } as any
            )
          },
          betVoided: (bet: Bet) => {
            set(
              (state) => {
                const betFromState = state.bets.byId[bet.id]

                if (betFromState) {
                  betFromState.status = betStatusMap.CANCELLED
                }
              },
              undefined,
              { type: 'betVoided', bet } as any
            )
          }
        }
      }))
    ),
    { name: 'BetStore', enabled: isDevtoolsEnabled() }
  )
)

export const useBetActions = () => useBetStore((state) => state.actions)

export const useGetBets = () =>
  useBetStore((state): Bet[] =>
    pipe(
      state.bets.allIds,
      A.map((id) => state.bets.byId[id]),
      A.map(O.fromNullable),
      A.getSomes
    )
  )

export const useGetBetsByGameAndPlayer = ({
  gameId,
  playerId
}: {
  gameId: string
  playerId: string
}) =>
  useBetStore((state): Bet[] =>
    pipe(
      state.bets.allIds,
      A.map((id) => state.bets.byId[id]),
      A.map(O.fromNullable),
      A.getSomes,
      A.filter((bet) => bet.raceConfigurationId === gameId && bet.playerId === playerId)
    )
  )

export const useIsUnacceptedBet = (betId?: string) =>
  useBetStore((state): boolean => {
    if (!betId) {
      return false
    }

    return pipe(state.unacceptedBets, Struct.get(betId), O.fromNullable, O.isSome)
  })
