import { playSoundEffect } from 'src/utils/soundEffects';
type Props = Readonly<{
  href: string;
  children: React.ReactNode;
  className?: string;
  label?: string;
}>;
export function ExternalLink({
  href,
  children,
  className,
  label
}: Props) {
  return <a className={`button ${className}`} href={href} onClick={() => {
    playSoundEffect('click');
  }} rel="noreferrer" target="_blank" data-sentry-component="ExternalLink" data-sentry-source-file="index.tsx">
      {label && <span className="label">{label}</span>}
      {children}
    </a>;
}