import { useClientSideTranslation } from 'src/services/i18n';
import type { MarbleGame } from 'src/types/domain/Game';
import AngleLeftIcon from 'src/app/assets/icons/ico-angle-left.svg';
import ClipboardListCheckIcon from 'src/app/assets/icons/ico-clipboard-list-check.svg';
import { useGetCurrentLanguage } from 'src/store/game';
import GameRulesSidebar from './GameRulesSidebar';
type Props = Readonly<{
  game: MarbleGame;
  onBackClick: () => void;
}>;
export function RulesPanel({
  game,
  onBackClick
}: Props) {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  return <div data-sentry-component="RulesPanel" data-sentry-source-file="RulesPanel.tsx">
      <div className="panel-header">
        <button className="back" onClick={onBackClick} type="button">
          <span className="icon">
            <AngleLeftIcon data-sentry-element="AngleLeftIcon" data-sentry-source-file="RulesPanel.tsx" />
          </span>
        </button>
        <ClipboardListCheckIcon data-sentry-element="ClipboardListCheckIcon" data-sentry-source-file="RulesPanel.tsx" />
        <span>{t('Rules')}</span>
      </div>
      <GameRulesSidebar game={game} data-sentry-element="GameRulesSidebar" data-sentry-source-file="RulesPanel.tsx" />
    </div>;
}