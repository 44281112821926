import { pipe, Array as A, Option as O, Order } from 'effect'
import type { Marble } from 'src/types/domain/Marble'
import { orderRacesByCreatedAt, type Race } from 'src/types/domain/Race'
import { isRaceSettledEvent, type RaceEvent } from 'src/types/domain/RaceEvent'
import { getOrdinalSuffix } from './getOrdinalSuffix'

export const formatRemainingTime = (eventTime: string, initialSeconds = 0) => {
  const targetTime = new Date(eventTime)
  const currentTime = new Date()
  const remainingSeconds = Math.max(
    initialSeconds - Math.floor((currentTime.getTime() - targetTime.getTime()) / 1000),
    0
  )
  const minutes = Math.floor(remainingSeconds / 60)
  const seconds = remainingSeconds % 60
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

export const getLatestEvent = (raceEvents: RaceEvent[]) => {
  if (raceEvents.length === 0) return undefined

  return raceEvents.sort(
    (a, b) => new Date(b.eventTimestamp).getTime() - new Date(a.eventTimestamp).getTime()
  )[0]
}

export const formatElapsedTime = (startTime: string, endTime?: string) => {
  if (!endTime) {
    const currentTime = new Date()
    endTime = currentTime.toISOString()
  }

  const start = new Date(startTime)
  const end = new Date(endTime)
  const elapsedSeconds = Math.floor((end.getTime() - start.getTime()) / 1000)
  const minutes = Math.floor(elapsedSeconds / 60)
  const seconds = elapsedSeconds % 60
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

export const orderResultsByPosition = Order.mapInput(
  Order.number,
  (result: { position: number; marble: Marble }) => result.position
)

export const getSortedRaceResults = (race: Race, marbles: Marble[]) =>
  pipe(
    race.raceEvents,
    A.findFirst(isRaceSettledEvent),
    O.map((event) => {
      return pipe(
        event.payload.results,
        A.map((result) =>
          pipe(
            marbles,
            A.findFirst((marble) => marble.uuid === result.marbleId),
            O.map((marble) => ({
              position: result.position,
              positionSuffix: getOrdinalSuffix(result.position),
              marble
            }))
          )
        ),
        A.getSomes,
        A.sort(orderResultsByPosition)
      )
    }),
    O.getOrUndefined
  )

export const isRaceSettled = (race: Race): boolean =>
  pipe(
    race.raceEvents,
    A.some((event) => event.eventType === 'race_settled')
  )

export interface Winner {
  raceId: string
  marble: Marble
}

const getWinnerFromRace =
  (marbles: Marble[]) =>
  (race: Race): O.Option<Winner> =>
    pipe(
      race.raceEvents,
      A.findFirst(isRaceSettledEvent),
      O.flatMap((event) =>
        pipe(
          event.payload.results,
          A.findFirst((result) => result.position === 1),
          O.map((result) => result.marbleId),
          O.flatMap((marbleId) => A.findFirst(marbles, (marble) => marble.uuid === marbleId)),
          O.map((marble) => ({
            raceId: race.uuid,
            marble
          }))
        )
      )
    )

export const getLastWinners = (
  races: Race[],
  marbles: Marble[],
  numberOfWinners: number
): Winner[] =>
  pipe(
    races,
    A.sortBy(orderRacesByCreatedAt),
    A.filter(isRaceSettled),
    A.map(getWinnerFromRace(marbles)),
    A.getSomes,
    A.take(numberOfWinners)
  )
